import { createSlice } from '@reduxjs/toolkit'

const getEventNewOrderSlice = createSlice({
    name: 'getEventNewOrder',
    initialState: {
        eventName: '',
    },
    reducers: {
        getEvent: (state, action) => {
            console.log(action)
            state.eventName = action.payload
        },
    },
})

export default getEventNewOrderSlice
