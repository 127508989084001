import { Typography, useTheme } from '@mui/material'
import { tokens } from '../theme'
import { ResponsiveBar } from '@nivo/bar'
import { mockBarNewUser } from '../data/mockData'

function BarChart({ nameChart, data, type, selectedDate }) {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    const renderKeys = () => {
        switch (nameChart) {
            case 'revenueKols':
                return ['Doanh thu']
            case 'StatisticsFee':
                return ['Hoa hồng']
            case 'StatisticsUser':
                return ['Người giới thiệu']
            case 'AnalyzesPartner':
                return ['Người lao động']
            default:
                return ['hot dog', 'burger', 'sandwich', 'kebab', 'fries', 'donut']
        }
    }
    const renderIndexBy = () => {
        switch (nameChart) {
            case 'revenueKols':
                return 'month'
            case 'StatisticsFee':
            case 'StatisticsUser':
            case 'AnalyzesPartner':
                return type === 'year' ? 'month' : 'day'
            case 'statisticsService':
                return 'day'
            default:
                return 'country'
        }
    }
    const renderLegendBottom = () => {
        switch (nameChart) {
            case 'revenueKols':
                return `Doanh thu năm ${selectedDate.year()}`
            case 'StatisticsFee':
                return type === 'year'
                    ? `Phí hoa hồng năm ${selectedDate.year()}`
                    : `Phí hoa hồng tháng ${selectedDate.month() + 1}`
            case 'StatisticsUser':
                return type === 'year'
                    ? `Số người giới thiệu năm ${selectedDate.year()}`
                    : `Số người giới thiệu tháng ${selectedDate.month() + 1}`
            case 'AnalyzesPartner':
                return type === 'year' ? `Người lao động mới năm ${selectedDate.year()}` : `Người lao động mới tháng ${selectedDate.month() + 1}`
            case 'statisticsService':
                return 'Tuần này'
            default:
                return 'country'
        }
    }
    const renderLegendLeft = () => {
        switch (nameChart) {
            case 'revenueKols':
                return 'Chỉ số (x 1.000.000)'
            case 'StatisticsFee':
            case 'AnalyzesPartner   ':
                return 'chỉ số'
            case 'statisticsService':
                return 'chỉ số'
            default:
                return 'food'
        }
    }

    // const formatChartData = (data) => {
    //     return data.map((item) => ({
    //         ...item,
    //         'Khách hàng': item.totalUser,
    //     }))
    // }

    const BarTotalsLayer = (props) => {
        const labelOffset = 10
        const labelFontSize = 18
        if (props.bars.length === 0) return null

        const totals = {}
        const bandwidth = props.bars[0].width

        props.bars.forEach((bar) => {
            const indexValue = bar.data.indexValue
            const totalValue = bar.data.value // Get the totalUser value
            if (!(indexValue in totals)) {
                totals[indexValue] = 0
            }
            if (!bar.data.hidden && totalValue > 0) {
                // Only consider values > 0
                totals[indexValue] += totalValue
            }
        })

        const labels = Object.keys(totals).map((indexValue) => {
            if (totals[indexValue] > 0) {
                // Only render if the total > 0
                // console.log('x', indexValue)

                const x = props.xScale(indexValue) + bandwidth / 2
                const y = props.yScale(totals[indexValue]) - labelOffset
                return (
                    <text
                        key={'total.' + indexValue}
                        x={x}
                        y={y}
                        textAnchor={'middle'}
                        fontWeight={'bold'}
                        fontSize={labelFontSize}
                        fill={colors.greenAccent[400]}
                    >
                        {totals[indexValue]}
                    </text>
                )
            }
            return null // Skip if the total is 0
        })

        return <>{labels}</>
    }

    const colorsColumnChart = {
        'Doanh thu': colors.orangeAccent[400],
        'Hoa hồng': colors.greenAccent[400],
        'Người giới thiệu': colors.orangeAccent[400],
        'Người lao động': colors.orangeAccent[400],
        'dọn nhà': '#E0E0E0',
        'sửa điện lạnh': '#B5C0D0',
        'chăm sóc em bé': '#A8CD9F',
        'chăm sóc người già': colors.greenAccent[400],
    }
    const getColor = (bar) => {
        return colorsColumnChart[bar.id]
    }

    return (
        <ResponsiveBar
            data={data}
            theme={{
                axis: {
                    domain: {
                        line: {
                            stroke: colors.grey[100],
                        },
                    },
                    legend: {
                        text: {
                            fill: colors.greenAccent[500],
                        },
                    },
                    ticks: {
                        line: {
                            stroke: colors.grey[100],
                            strokeWidth: 1,
                        },
                        text: {
                            fill: colors.grey[100],
                        },
                    },
                },
                legends: {
                    text: {
                        fill: colors.grey[100],
                    },
                },
                tooltip: {
                    basic: {
                        color: 'black',
                    },
                },
                // labels: {
                //     text: {
                //         fontSize: 14,
                //         stroke: `${colors.greenAccent[600]}`,
                //     },
                // }, //đổi màu cho total text khi dùng prop enableTotals
            }}
            keys={renderKeys()}
            indexBy={renderIndexBy()}
            margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
            padding={0.3}
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            // colors={{ scheme: 'nivo' }}
            colors={data ? getColor : { scheme: 'nivo' }}
            layers={['grid', 'axes', 'bars', 'totals', 'markers', 'legends', 'annotations', BarTotalsLayer]}
            defs={[
                {
                    id: 'dots',
                    type: 'patternDots',
                    background: 'inherit',
                    color: '#38bcb2',
                    size: 4,
                    padding: 1,
                    stagger: true,
                },
                {
                    id: 'lines',
                    type: 'patternLines',
                    background: 'inherit',
                    color: '#eed312',
                    rotation: -45,
                    lineWidth: 6,
                    spacing: 10,
                },
            ]}
            fill={[
                {
                    match: {
                        id: 'fries',
                    },
                    id: 'dots',
                },
                {
                    match: {
                        id: 'sandwich',
                    },
                    id: 'lines',
                },
            ]}
            borderColor={{
                from: 'color',
                modifiers: [['darker', 1.6]],
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: renderLegendBottom(),
                legendPosition: 'middle',
                legendOffset: 32,
                truncateTickAt: 0,
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: renderLegendLeft(),
                legendPosition: 'middle',
                legendOffset: -40,
                truncateTickAt: 0,
                tickValues: 5, // giảm chỉ số cần show ở trục y, chỉ show 5 chỉ số
            }}
            // enableTotals={true}
            // enableGridY={false}
            gridYValues={5} // giảm số kẻ ngan, 5 đường kẻ ngan
            enableLabel={false}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{
                from: 'color',
                modifiers: [['darker', 1.6]],
            }}
            legends={[
                {
                    dataFrom: 'keys',
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: false,
                    translateX: 120,
                    translateY: 0,
                    itemsSpacing: 2,
                    itemWidth: 100,
                    itemHeight: 20,
                    itemDirection: 'top-to-bottom',
                    itemOpacity: 0.85,
                    symbolSize: 20,
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemOpacity: 1,
                            },
                        },
                    ],
                },
            ]}
            role='application'
            ariaLabel='Nivo bar chart demo'
            barAriaLabel={(e) => e.id + ': ' + e.formattedValue + ' in country: ' + e.indexValue}
        />
    )
}

export default BarChart
