import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  TextField,
  Typography,
  useTheme,
  Button as ButtonMui,
  CircularProgress,
  InputAdornment,
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from "@mui/material";
import { tokens } from "@src/theme";
import { message } from "antd";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import {
  create_user_bank_account,
  get_user_bank_account_by_userid,
} from "@utils/api/apiList";
import { Person } from "@mui/icons-material";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import request from "@utils/api/request";
import useMediaQuery from "@mui/material/useMediaQuery";
import * as yup from "yup";
import axios from "axios";
import "antd/dist/reset.css";

const bankInfoSchema = yup.object().shape({
  bankAccountNumber: yup.string().required("Không được để trống!"),
  bankAccountName: yup.string().required("Không được để trống!"),
  userAccountName: yup.string().required("Không được để trống!"),
});

function TabBankAccount({ userId }) {
  const formikRef = useRef(null);
  const theme = useTheme();

  const colors = tokens(theme.palette.mode);
  const matches = useMediaQuery("(min-width:900px)");

  const [banks, setBanks] = useState([]);
  const [initialBankInfoValues, setInitialBankInfoValues] = useState({
    bankAccountNumber: "",
    bankAccountName: "",
    userAccountName: "",
  });

  const [loadingProfile, setLoadingProfile] = useState(null);
  //state redux
  const dispatch = useDispatch();
  const [beforeSubmit, setBeforeSubmit] = useState(false);

  //fetchData Account
  const fetchData = () => {
    request
      .post(get_user_bank_account_by_userid, { userId: userId })
      .then((res) => {
        const data = res.data.data;

        setInitialBankInfoValues({
          bankAccountNumber: data[data.length - 1]?.bankAccountNumber || "",
          bankAccountName: data[data.length - 1]?.bankAccountName || "",
          userAccountName: data[data.length - 1]?.userAccountName || "",
        });
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchData();
  }, []);

  //fetch data bank VN
  const fetchBanks = async () => {
    try {
      const response = await axios.get("https://api.vietqr.io/v2/banks");
      if (response.data.code === "00") {
        setBanks(response.data.data);
        console.log(response.data.data);
      } else {
        console.error(response);
      }
    } catch (err) {
      console.error("error: ", err);
    }
  };

  useEffect(() => {
    fetchBanks();
  }, []);

  //submit profile
  const handleSubmitProfile = (values, { resetForm, setFieldError }) => {
    setBeforeSubmit(true);
  };

  //update bank
  const fecthUpdateBankInfo = (data, resetForm, setFieldError) => {
    request
      .post(create_user_bank_account, { ...data, userId: userId })
      .then((res) => {
        setLoadingProfile(false);
        setBeforeSubmit(false);
        message.success({
          content: "Cập nhật thông tin ngân hàng thành công!",
        });
      })
      .catch((err) => {
        const textErr = err.response.data.data;
        if (textErr) {
          message.error(
            "Cập nhật thông tin ngân hàng thất bại, vui lòng thử lại!"
          );
        }
        console.error("Lỗi cập nhật thông tin ngân hàng", err);
      })
      .finally(() => setLoadingProfile(false));
  };

  const handleBeforeSubmitClose = () => {
    setBeforeSubmit(false);
  };

  const handleSubmitDialog = () => {
    setLoadingProfile(true);
    const formValues = formikRef.current.values; // Lấy giá trị form
    const setFieldError = formikRef.current.setFieldError; // Lấy hàm setFieldError
    const resetForm = formikRef.current.resetForm;
    const timer = setTimeout(() => {
      fecthUpdateBankInfo(formValues, resetForm, setFieldError);
    }, 500);
    return () => clearTimeout(timer);
  }

  return (
    <Box>
      <Formik
        innerRef={formikRef}
        onSubmit={(values, { setFieldError, resetForm }) =>
          handleSubmitProfile(values, { setFieldError, resetForm })
        }
        initialValues={initialBankInfoValues}
        enableReinitialize={true}
        validationSchema={bankInfoSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              sx={{
                "& .MuiInputLabel-root.MuiInputLabel-formControl": {
                  fontSize: "14px", // default size when no data and not focused
                },
                "& .MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-shrink":
                {
                  fontSize: "16px", // size when there is data or focused
                },
                "& .MuiInputLabel-formControl.Mui-error.Mui-focused": {
                  fontSize: "16px !important",
                  color: theme.palette.mode === "dark" ? "#f44336" : "#d32f2f",
                },
                "& .MuiFormHelperText-root.Mui-error": {
                  fontSize: "14px",
                },
                "& fieldset": {
                  fontSize: "16px !important",
                },
              }}
            >
              <Typography
                variant="h5"
                fontWeight="600"
                ml={{ xs: 0, sm: "10%" }}
                mb={2}
              >
                Cập nhật thông tin ngân hàng
              </Typography>
              <Typography
                pb={2}
                ml={{ xs: 0, sm: "10%" }}
                color={
                  theme.palette.mode === "dark"
                    ? colors.grey[100]
                    : colors.grey[300]
                }
              >
                Phần thông tin này chỉ dùng cho mục đích giao dịch rút tiền của
                bạn, ngoài ra chúng rồi không sử dụng vào mục đích khác.
              </Typography>
              <Typography
                ml={{ xs: 0, sm: "10%" }}
                color={
                  theme.palette.mode === "dark"
                    ? colors.grey[100]
                    : colors.grey[300]
                }
              >
                Bạn vui lòng kiểm tra kỹ thông tin trước khi lưu nhé!
              </Typography>
              <Box
                mt="20px"
                display="flex"
                flexDirection="column"
                maxWidth={{ xs: "auto", sm: "600px" }}
                ml={{ xs: 0, sm: "20%" }}
              >
                <TextField
                  className={
                    theme.palette.mode === "dark" ? "input-dark" : "input-light"
                  }
                  sx={{
                    mr: { xs: 0, sm: "20px" },
                    "& input": {
                      textTransform: "uppercase",
                    },
                  }}
                  variant="outlined"
                  label="Tên chủ thẻ"
                  placeholder="Tên chủ thẻ không dấu"
                  name="userAccountName"
                  value={values.userAccountName || ""}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={!!touched.userAccountName && !!errors.userAccountName}
                  helperText={touched.userAccountName && errors.userAccountName}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Person sx={{ mr: 2 }} />
                      </InputAdornment>
                    ),
                  }}
                />
                <Box
                  className="box"
                  sx={{
                    "& .Mui-focused": {
                      color: `${colors.grey[100]} !important`,
                    },
                    paddingRight: { xs: 0, sm: "20px" },
                  }}
                >
                  <Autocomplete
                    freeSolo
                    options={banks}
                    value={values.bankAccountName || ""}
                    getOptionLabel={(option) =>
                      typeof option === "string" ? option : option.shortName
                    }
                    isOptionEqualToValue={(option, value) =>
                      typeof value === "string"
                        ? option.shortName === value
                        : option.shortName === value.shortName
                    }
                    onChange={(event, value) => {
                      if (typeof value === "string") {
                        setFieldValue("bankAccountName", value);
                      } else if (value && value.shortName) {
                        setFieldValue("bankAccountName", value.shortName);
                      } else {
                        setFieldValue("bankAccountName", "");
                      }
                    }}
                    onInputChange={(event, value) => {
                      setFieldValue("bankAccountName", value);
                    }}
                    onBlur={handleBlur}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className={
                          theme.palette.mode === "dark"
                            ? "input-dark"
                            : "input-light"
                        }
                        name="bankAccountName"
                        label="Chọn ngân hàng"
                        error={
                          !!touched.bankAccountName && !!errors.bankAccountName
                        }
                        helperText={
                          touched.bankAccountName && errors.bankAccountName
                        }
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              <AccountBalanceOutlinedIcon sx={{ mr: '10px', ml: '4px' }} />
                            </InputAdornment>
                          ),
                        }}
                        width={{ xs: "100%!important", sm: "100%!important" }}
                      />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} key={option.id || option}>
                        {typeof option === "string"
                          ? option
                          : `${option.shortName} - ${option.name}`}
                      </li>
                    )}
                    fullWidth
                  />
                </Box>
                <TextField
                  className={
                    theme.palette.mode === "dark" ? "input-dark" : "input-light"
                  }
                  sx={{ mr: { xs: 0, sm: "20px" } }}
                  variant="outlined"
                  label="Số tài khoản"
                  name="bankAccountNumber"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.bankAccountNumber}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CreditCardIcon sx={{ mr: 2 }} />
                      </InputAdornment>
                    ),
                  }}
                  error={
                    !!touched.bankAccountNumber && !!errors.bankAccountNumber
                  }
                  helperText={
                    touched.bankAccountNumber && errors.bankAccountNumber
                  }
                />
              </Box>
              <ButtonMui
                sx={{
                  ml: { xs: 0, sm: "20%" },
                  background:
                    theme.palette.mode === "dark" ? "#138567" : "#3da58a",
                  fontWeight: "600",
                  width: "74px",
                  "&:hover": {
                    bgcolor: "#188b6d",
                  },
                }}
                type="submit"
                variant="contained"
              >
                {loadingProfile ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Lưu"
                )}
              </ButtonMui>
            </Box>
          </form>
        )}
      </Formik>
      {!!beforeSubmit && (
        <Dialog
          open={beforeSubmit}
          onClose={handleBeforeSubmitClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" sx={{ fontSize: "16px" }}>
            {"Xác nhận lưu thông tin ngân hàng"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Bạn chỉ được nhập thông tin này một lần và sẽ không thể chỉnh sửa
              sau khi đã lưu. Vui lòng kiểm tra kỹ các thông tin sau:
            </DialogContentText>
            <DialogContentText mt={2} sx={{ fontWeight: 600 }}>
              Tên ngân hàng: {formikRef.current.values?.bankAccountName}
            </DialogContentText>
            <DialogContentText sx={{ fontWeight: 600 }}>
              Số tài khoản: {formikRef.current.values?.bankAccountNumber}
            </DialogContentText>
            <DialogContentText sx={{ fontWeight: 600 }}>
              Tên chủ tài khoản: <span style={{textTransform: 'uppercase'}}>{formikRef.current.values?.userAccountName}</span>
            </DialogContentText>
            <DialogContentText mt={2}>
              Nếu tất cả thông tin đều chính xác, nhấn{" "}
              <span style={{ fontWeight: 600 }}>"Xác nhận"</span> để lưu. Nếu
              cần điều chỉnh, nhấn{" "}
              <span style={{ fontWeight: 600 }}>"Quay lại"</span>.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <ButtonMui sx={{
              color: theme.palette.mode === "dark" ? 'white' : '#403d3d'
            }} onClick={handleBeforeSubmitClose}>Quay lại</ButtonMui>
            <ButtonMui
              sx={{
                ml: { xs: 0, sm: "20%" },
                background:
                  theme.palette.mode === "dark" ? "#138567" : "#3da58a",
                fontWeight: "600",
                width: "90px",
                "&:hover": {
                  bgcolor: "#188b6d",
                },
              }}
              type="submit"
              variant="contained"
              onClick={handleSubmitDialog}
              autoFocus
            >
              Xác nhận
            </ButtonMui>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
}

export default TabBankAccount;
