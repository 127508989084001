import { useTheme } from '@mui/material'
import { tokens } from '../../theme'

function StyleButtonGradient() {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    return {
        backgroundColor: colors.blueAccent[700],
        background:
            theme.palette.mode === 'dark'
                ? '#3e4396' //'linear-gradient(to right, #2b5876, #4e4376)'
                : 'linear-gradient(to right, #b993d6, #8ca6db)',
        // color: colors.grey[100],
        color: 'white',
        fontSize: '14px',
        fontWeight: 'bold',
        padding: '10px 20px',
        '&:hover': {
            background:
                theme.palette.mode === 'dark'
                    ? '#3e4366' //'linear-gradient(to right, #346382, #5b5083)'
                    : 'linear-gradient(to right, #c8b7d5, #a6beee)',
        },
    }
}

export default StyleButtonGradient
