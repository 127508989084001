import { Cancel, Close, Send } from "@mui/icons-material";
import {
  Box,
  Typography,
  IconButton,
  useTheme,
  Button,
} from "@mui/material";
import { Formik } from "formik";
import { Button as ButtonAntd, message } from "antd";
import { tokens } from "@src/theme";
import { baseURL } from "@utils/api/baseURL";
import { useEffect, useState } from "react";
import { get_user_bank_account_by_userid, get_user_profile, kol_create_withdrawal_request } from "@src/utils/api/apiList";
import { formatNumber, parseNumber } from '@components/prefixData'
import CustomLoadingComponent from "@components/loading";
import TextFieldCustom from "@components/form/textfield";
import * as yup from 'yup'
import ModalCustom from "@components/modal/modal";
import request from "@src/utils/api/request";
import { useNavigate } from "react-router-dom";
import { routerLinks } from "@src/routes/constant";

function ModalWithdrawalsRequest({
  open,
  onClose,
  onSubmitRequest,
  isLoading,
}) {
  const navigate = useNavigate()
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const userId = localStorage.getItem("userId");
  const token = localStorage.getItem("token");
  const [userInfo, setUserInfo] = useState(null);
  const [userInfoBank, setUserInfoBank] = useState(null);
  const [loading, setLoading] = useState(false);

  //fetchData Account
  const fetchData = () => {
    request.setAuthToken(token);
    request
      .post(get_user_profile, { info: { _id: userId } })
      .then((res) => {
        const data = res.data.data;
        console.log(data);

        setUserInfo({ ...data });
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchData();
  }, []);

  //fetchData Bank Account
  const fetchDataBankAcc = () => {
    request
      .post(get_user_bank_account_by_userid, { userId: userId })
      .then((res) => {
        const data = res.data.data;
        console.log('userInfoBank', data.length > 0)
        setUserInfoBank({ ...data })

      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchDataBankAcc();
  }, []);
  //validate box add
  const validateSchema = yup.object().shape({
    requestPoint: yup
      .number()
      .typeError('Số điểm phải là số')
      .positive('Số điểm phải lớn hơn 0')
      .min(100, 'Số điểm phải tối thiểu là 100.000')
      .test('is-greater-than-available', `Số điểm phải nhỏ hơn hoặc bằng số điểm hiện có`,
        value => value <= userInfo?.point
      )
      .required('Số điểm là bắt buộc'),
  });

  const handleSubmitRequest = (data, { setFieldError, resetForm }) => {
    if (Object.keys(userInfoBank || {}).length !== 0) {
      setLoading(true)
      console.log('giang', Number(data.requestPoint))
      request
        .post(kol_create_withdrawal_request, { requestPoint: Number(data.requestPoint) })
        .then((res) => {
          message.success('Gửi yêu cầu rút tiền thành công!')
          !!onSubmitRequest && onSubmitRequest()

          resetForm();
        })
        .catch((err) => {
          if (!!err) {
            message.error("Số điểm phải tối thiểu là 100.000, xin thử lại!");
            setFieldError("requestPoint", "Số điểm không đủ!");
          } else {
            message.error("Gửi yêu cầu thất bại, xin thử lại!");

          }
        })
        .finally(() => setLoading(false));
    } else {
      !!onClose && onClose()
      navigate(routerLinks.KOLsProfile, { state: { updateBankInfo: true } })
    }
  };


  const styleBox = {
    "& .MuiBox-root": {
      display: "flex",
      alignItems: "center",
      gap: "20px",
      width: "80%",
      "& .MuiTextField-root": {
        flex: 1,
      },
    },
    "& .MuiTypography-root": {
      minWidth: "120px",
      fontWeight: 600,
      fontSize: 14,
    },
    "& .MuiBox-root.select": {
      width: "100%",
    },
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "20px",
    // maxHeight: '700px',
    maxHeight: "70vh",
    overflowY: "auto",
    pb: "10px",
    pt: "20px",
    backgroundColor:
      theme.palette.mode === "dark" ? colors.primary[400] : "#e7e7e7",
    borderRadius: "5px",
    "& .Mui-focused fieldset": {
      borderColor:
        theme.palette.mode === "dark"
          ? "rgba(255, 255, 255, 0.6) !important"
          : "rgba(0, 0, 0, 0.8)", // Màu border khi focused
      borderWidth: theme.palette.mode === "dark" ? "2px" : "1px !important",
    },
  };

  return (
    <ModalCustom
      open={open}
      onClose={(event, reason) => {
        if (reason === "backdropClick" || reason === "escapeKeyDown") {
          return;
        }
        onClose();
      }}
      style={{ zIndex: "99" }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h4" mb={"10px"} color={colors.grey[300]}>
          YÊU CẦU RÚT TIỀN
        </Typography>
      </Box>
      <IconButton
        sx={{
          position: "absolute",
          top: "10px",
          right: "10px",
        }}
        onClick={onClose}
      >
        <Close color="disabled" />
      </IconButton>

      <Box my={2}>
        <Typography fontSize={16}>
          Số điểm thưởng hiện có: <span style={{ fontWeight: "600" }}>{Intl.NumberFormat('en-US').format(userInfo?.point)}</span>
        </Typography>
        {Object.keys(userInfoBank || {}).length !== 0 ? <Typography
          mt={2}
          color={
            theme.palette.mode === "dark" ? colors.grey[100] : colors.grey[300]
          }
        >
          Bạn hãy nhập số điểm cần rút vào phía dưới, lưu ý số điểm không được
          lớn quá số điểm hiện có.
        </Typography> : <Typography
          mt={2}
          color={
            theme.palette.mode === "dark" ? colors.grey[100] : colors.grey[300]
          }
        >
          Bạn chưa có thông tin ngân hàng để thực hiện rút điểm thưởng. Vui lòng cung cấp thông tin để chúng tôi hỗ trợ bạn hoàn tất quá trình nhé!
        </Typography>}
      </Box>

      <Box>
        <Formik
          enableReinitialize={true}
          initialValues={{ requestPoint: 0 }}
          validationSchema={Object.keys(userInfoBank || {}).length !== 0 ? validateSchema : null}
          onSubmit={(values, { setFieldError, resetForm }) =>
            handleSubmitRequest(values, { setFieldError, resetForm })
          }
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => {
            return (
              <Box
                component="form"
                display="flex"
                flexDirection="column"
                sx={styleBox}
                onSubmit={handleSubmit}
              >
                {Object.keys(userInfoBank || {}).length !== 0 && <>{isLoading && (
                  <Box
                    position="absolute"
                    top={0}
                    left={0}
                    right={0}
                    bottom={0}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    bgcolor="rgba(255, 255, 255, 0.2)"
                    zIndex={2}
                    width="100% !important"
                  >
                    <CustomLoadingComponent />
                  </Box>
                )}
                  <TextFieldCustom
                    label="Số điểm"
                    name="requestPoint"
                    value={formatNumber(values.requestPoint)}
                    onChange={(e) => {
                        const rawValue = parseNumber(e.target.value)
                        setFieldValue('requestPoint', Number(rawValue))
                    }}
                    onBlur={handleBlur}
                    error={!!touched.requestPoint && !!errors.requestPoint}
                    helperText={touched.requestPoint && errors.requestPoint}
                    widthField="80%"
                  /></>}

                <Box
                  justifyContent="end"
                  mt="20px"
                  sx={{
                    width: { xs: "100% !important", sm: "80% !important" },
                  }}
                >
                  <Button
                    sx={{ minWidth: "96px", p: "8px 16px" }}
                    type="submit"
                    variant="contained"
                    color="success"
                    endIcon={<Send />}
                  >
                    {Object.keys(userInfoBank || {}).length !== 0 ? 'Gửi' : 'Tiếp tục'}
                  </Button>
                  <Button
                    sx={{ minWidth: "96px", p: "8px 16px" }}
                    variant="outlined"
                    color="inherit"
                    startIcon={<Cancel />}
                    onClick={onClose}
                  >
                    Huỷ
                  </Button>
                </Box>
              </Box>
            );
          }}
        </Formik>
      </Box>
    </ModalCustom>
  );
}

export default ModalWithdrawalsRequest;
