import React, { useEffect, Fragment, useState } from 'react'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { ColorModeContext, useMode } from './theme'
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom'
import { publicRoutes, privateRoutes } from './routes/routes'
import { notification } from 'antd'
import {
    partner_cancel_task,
    partner_done_task,
    socket_result,
    user_cancel_task_after_pending,
    user_cancel_task_before_pending,
    user_check_done_clean,
    user_create_task,
} from './utils/api/apiSocket'
import { useDispatch } from 'react-redux'
import { routerLinks } from './routes/constant'
import getEventNewOrderSlice from './redux/slice/getNewOrderSlice'
import connectSocket from './utils/api/connectSocket'
import AdminLayout from './layout/AdminLayout'
import LoginLayout from './layout/loginLayout/LoginLayout'

function App() {
    const [theme, colorMode] = useMode()
    const tokenStorage = localStorage.getItem('token')
    const navigate = useNavigate()
    const dispatch = useDispatch()
    //role
    const role = localStorage.getItem('role')

    //notification
    const [api, contextHolder] = notification.useNotification()
    const notificationRoutes = {
        createTaskBooking: routerLinks.TaskBooking,
        editTaskBooking: routerLinks.TaskBooking,
        createPermanentBooking: routerLinks.PermanentTask,
        editPermanentBooking: routerLinks.PermanentTask,
        createCleanBooking: routerLinks.Clean,
        editCleanBooking: routerLinks.Clean,
        createAirBooking: routerLinks.Aircondition,
        editAirBooking: routerLinks.Aircondition,
        createSprayBooking: routerLinks.Spray,
        editSprayBooking: routerLinks.Spray,
        createSofaBooking: routerLinks.Sofa,
        editSofaBooking: routerLinks.Sofa,
        default: routerLinks.TaskBooking,
    }

    const openNotification = (type, info, action) => {
        let orderType = info?.data?.type || 'default'
        // console.log('orderType', orderType)
        // console.log('navigate', notificationRoutes[orderType])

        const descriptions = {
            newOrder: {
                createTaskBooking: 'có đơn hàng "ca lẻ" mới',
                editTaskBooking: (
                    <p>
                        có đơn hàng <span style={{ color: 'orange' }}>"ca lẻ"</span> đã được <span style={{ color: 'orange' }}>chỉnh sửa</span> bởi
                        người dùng
                    </p>
                ),
                createPermanentBooking: 'có đơn hàng "ca cố định" mới',
                editPermanentBooking: (
                    <p>
                        có đơn hàng <span style={{ color: 'orange' }}>"ca cố định"</span> đã được <span style={{ color: 'orange' }}>chỉnh sửa</span>{' '}
                        bởi người dùng
                    </p>
                ),
                createCleanBooking: 'có đơn hàng "Tổng vệ sinh" mới',
                editCleanBooking: (
                    <p>
                        có đơn hàng <span style={{ color: 'orange' }}>"tổng vệ sinh"</span> đã được <span style={{ color: 'orange' }}>chỉnh sửa</span>{' '}
                        bởi người dùng
                    </p>
                ),
                createSofaBooking: 'có đơn hàng "Sofa" mới',
                editSofaBooking: (
                    <p>
                        có đơn hàng <span style={{ color: 'orange' }}>"sofa"</span> đã được <span style={{ color: 'orange' }}>chỉnh sửa</span> bởi
                        người dùng
                    </p>
                ),
                createAirBooking: 'có đơn hàng "điều hoà" mới',
                editAirBooking: (
                    <p>
                        có đơn hàng <span style={{ color: 'orange' }}>"điều hoà"</span> đã được <span style={{ color: 'orange' }}>chỉnh sửa</span> bởi
                        người dùng
                    </p>
                ),
                createSprayBooking: 'có đơn hàng "phun khử khuẩn" mới',
                editSprayBooking: (
                    <p>
                        có đơn hàng <span style={{ color: 'orange' }}>"phun khử khuẩn"</span> đã được{' '}
                        <span style={{ color: 'orange' }}>chỉnh sửa</span> bởi người dùng
                    </p>
                ),
                default: 'có đơn hàng mới',
            },
            partnerDoneTask: {
                doneTaskBooking: 'có đơn hàng "ca lẻ" đã hoàn thành',
                donePermanentBooking: 'có đơn hàng "ca cố định" đã hoàn thành',
                doneCleanBooking: 'có đơn hàng "Tổng vệ sinh" đã hoàn thành',
                doneSofaBooking: 'có đơn hàng "sofa" đã hoàn thành',
                doneAirBooking: 'có đơn hàng "điều hoà" đã hoàn thành',
                doneSprayBooking: 'có đơn hàng "phun khử khuẩn" đã hoàn thành',
                default: 'có đơn hàng đã hoàn thành',
            },
            partnerCancelTask: {
                deleteTaskBooking: 'có đơn hàng "ca lẻ" đã bị huỷ bởi partner',
                deletePermanentBooking: 'có đơn hàng "ca cố định" đã bị huỷ bởi partner',
                deleteCleanBooking: 'có đơn hàng "Tổng vệ sinh" đã bị huỷ bởi partner',
                deleteSofaBooking: 'có đơn hàng "sofa" đã bị huỷ bởi partner',
                deleteAirBooking: 'có đơn hàng "điều hoà" đã bị huỷ bởi partner',
                deleteSprayBooking: 'có đơn hàng "phun khử khuẩn" đã bị huỷ bởi partner',
                default: 'có đơn hàng đã bị huỷ bởi partner',
            },
            userCancelTask: {
                deleteTaskBooking: 'có đơn hàng "ca lẻ" đã bị huỷ bởi người dùng',
                deletePermanentBooking: 'có đơn hàng "ca cố định" đã bị huỷ bởi người dùng',
                deleteCleanBooking: 'có đơn hàng "Tổng vệ sinh" đã bị huỷ bởi người dùng',
                deleteSofaBooking: 'có đơn hàng "sofa" đã bị huỷ bởi người dùng',
                deleteAirBooking: 'có đơn hàng "điều hoà" đã bị huỷ bởi người dùng',
                deleteSprayBooking: 'có đơn hàng "phun khử khuẩn" đã bị huỷ bởi người dùng',
                default: 'có đơn hàng đã bị huỷ bởi người dùng',
            },
            // userCheckDoneClean: {
            //     taskbooking: 'có đơn hàng "ca lẻ" đã hoàn thành',
            //     default: 'có đơn hàng đã hoàn thành',
            // },
        }

        const description = descriptions[action][orderType] || descriptions[action].default

        const navigateToOrder = () => navigate(notificationRoutes[orderType], { state: { infoWork: info } })

        api[type]({
            message: 'Thông báo',
            description,
            onClick: () => {
                // Điều hướng đến đơn hàng
                navigateToOrder()
                // Đóng thông báo
                api.destroy()
            },
            style: {
                cursor: 'pointer',
            },
        })
    }

    //event socket

    // useEffect(() => {
    //     const socket = connectSocket()
    //     socket.on('connect', () => {
    //         console.log('Socket is connected')
    //     })

    //     {
    //         role !== 'admin' &&
    //             socket.on(socket_result, (info) => {
    //                 console.log(info)
    //                 if (info.success) {
    //                     if (info.key === user_create_task) {
    //                         dispatch(getEventNewOrderSlice.actions.getEvent(info))
    //                         switch (info.data.type) {
    //                             case 'editTaskBooking':
    //                             case 'editCleanBooking':
    //                             case 'editPermanentBooking':
    //                             case 'editSofaBooking':
    //                             case 'editAirBooking':
    //                             case 'editSprayBooking':
    //                                 openNotification('warning', info, 'newOrder')
    //                                 break
    //                             default:
    //                                 openNotification('success', info, 'newOrder')
    //                                 break
    //                         }
    //                         console.log(info)
    //                         //sau se tách ra thành dispatch 3 event của 3 ca. tránh rerender ko cần thiết
    //                     }
    //                     if (info.key === partner_done_task) {
    //                         openNotification('info', info, 'partnerDoneTask')
    //                         dispatch(getEventNewOrderSlice.actions.getEvent(info))
    //                     }
    //                     if (info.key === partner_cancel_task) {
    //                         dispatch(getEventNewOrderSlice.actions.getEvent(info))
    //                         openNotification('warning', info, 'partnerCancelTask')
    //                     }
    //                     if (info.key === user_cancel_task_before_pending || info.key === user_cancel_task_after_pending) {
    //                         dispatch(getEventNewOrderSlice.actions.getEvent(info))
    //                         // openNotification('warning', info, 'userCancelTask')
    //                         // dựa trên type app trả về để chống bắn noti 2 lần trên site
    //                         switch (info.data.type) {
    //                             case 'editTaskBooking':
    //                             case 'editPermanentBooking':
    //                             case 'editCleanBooking':
    //                             case 'editSofaBooking':
    //                             case 'editAirBooking':
    //                             case 'editSprayBooking':
    //                                 break
    //                             default:
    //                                 openNotification('warning', info, 'userCancelTask')
    //                                 break
    //                         }
    //                     }
    //                     if (info.key === 'admin_balance_change') {
    //                         console.log('sự kiện biến động số dư company')
    //                         console.log('data', info)
    //                     }
    //                 } else {
    //                     console.warn('Socket received error response:', info)
    //                 }
    //             })
    //     }

    //     return () => socket.disconnect(true)
    // }, [])

    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <div className='app'>
                    {contextHolder}
                    <Routes>
                        {publicRoutes.map((route, index) => {
                            let Layout = AdminLayout

                            if (route.layout) {
                                Layout = route.layout
                            } else if (route.layout === null) {
                                Layout = Fragment
                            }

                            const Page = route.component

                            return (
                                <Route
                                    key={index}
                                    path={route.path}
                                    element={
                                        tokenStorage && tokenStorage.length > 0 ? (
                                            <Layout>
                                                <Page />
                                            </Layout>
                                        ) : (
                                            <Navigate to={'/login'} />
                                        )
                                    }
                                />
                            )
                        })}
                        {privateRoutes.map((route, index) => {
                            let Layout = LoginLayout

                            if (route.layout) {
                                Layout = route.layout
                            } else if (route.layout === null) {
                                Layout = Fragment
                            }

                            const Page = route.component

                            return (
                                <Route
                                    key={index}
                                    path={route.path}
                                    element={
                                        <Layout>
                                            <Page />
                                        </Layout>
                                    }
                                />
                            )
                        })}
                    </Routes>
                </div>
            </ThemeProvider>
        </ColorModeContext.Provider>
    )
}


export default App