import { createSlice } from '@reduxjs/toolkit'

const getEmloyeeSlice = createSlice({
    name: 'getEmployeeData',
    initialState: {
        data: [],
    },
    reducers: {
        getEmployee: (state, action) => {
            state.data.push(action.payload)
        },
    },
})

export default getEmloyeeSlice
