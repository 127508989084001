import axios from 'axios'
import { baseURL } from './baseURL'

//base request
export const requestLogin = axios.create({
    baseURL: baseURL + 'users/',
})

const request = axios.create({
    baseURL,
})

request.setAuthToken = (jwtToken) => {
    request.defaults.headers.common.Authorization = `Bearer ${jwtToken}`
}

export default request
