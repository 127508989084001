import { useState, useEffect } from 'react'
import { Box, useTheme, Typography, Button } from '@mui/material'
import { tokens } from '@src/theme'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { total_user_ref_from_date_to_date, total_user_by_month_by_year } from '@utils/api/apiList'
import { DatePicker as DatePickerAntd, Space, message } from 'antd'
import LineChart from '@components/LineChart'
import request from '@utils/api/request'
import Header from '@components/Header'
import BarChart from '@components/BarChart'
import moment from 'moment'
import dayjs from 'dayjs'
import 'moment/locale/vi'

function getDaysInMonth(month, year) {
    return new Date(year, month, 0).getDate()
}

const KOLsStatisticsUser = () => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    const [dataUser, setDataUser] = useState(null)
    const [selectedDate, setSelectedDate] = useState(moment())
    const [viewMode, setViewMode] = useState('year')
    const [totalUsers, setTotalUsers] = useState(null)
    const [dateRange, setDateRange] = useState([dayjs().add(-7, 'd'), dayjs()])
    const [startDate, setStartDate] = useState(dayjs().add(-7, 'd'))
    const [endDate, setEndDate] = useState(dayjs())


    // // Fetch data year hoặc tháng
    // const fetchData = (data, type) => {
    //     let dataPayload = {}
    //     let api

    //     if (type === 'year') {
    //         // dataPayload = { year: data }
    //         dataPayload = {
    //             "startDate":"2024-09-01T05:00:00.000+00:00",
    //             "endDate":"2024-10-04T05:00:00.000+00:00"
    //         }
    //         api = total_user_ref_from_date_to_date
    //     } else if (type === 'month') {
    //         // dataPayload = { month: data + 1, year: selectedDate.year() }
    //         dataPayload = {
    //             "startDate":"2024-09-01T05:00:00.000+00:00",
    //             "endDate":"2024-10-04T05:00:00.000+00:00"
    //         }
    //         api = total_user_ref_from_date_to_date
    //     }

    //     request
    //         .post(api, { ...dataPayload })
    //         .then((res) => {
    //             console.log('data user', res)
    //             if (type === 'year') {
    //                 const { filledData, totalUsers } = fillMissingMonths(res.data.data)
    //                 setDataUser(filledData)
    //                 setTotalUsers(totalUsers)
    //                 console.log(filledData)
    //             } else {
    //                 // setDataUser(res.data.data)
    //                 const filledData = fillMissingDays(res.data.data.totalUserByMonth, selectedDate.month() + 1, selectedDate.year())
    //                 console.log(filledData)

    //                 setDataUser(filledData)
    //                 setTotalUsers(res.data.data.totalUserCount)
    //             }
    //         })
    //         .catch((err) => {
    //             message.error('Lỗi dữ liệu, vui lòng liên hệ dev!')
    //             console.error('err', err)
    //         })
    // }

    // Hàm chuyển đổi dữ liệu từ API thành format của Nivo Line Chart (theo thời điểm từ ngày đến ngày)
    const convertChartDataDateByDate = (apiData) => {
        const depositData = {
            id: 'Tiền cọc',
            data: [],
        }

        let totalUser = 0

        // Duyệt qua từng phần tử trong dữ liệu trả về từ API
        apiData?.forEach((entry) => {
            console.log('entry' ,entry)
            const { day, month, year } = entry._id
            const dateLabel = `${day}/${month}/${year}`
            const user = entry.totalUser

            depositData.data.push({ x: dateLabel, y: user })

            totalUser += user
        })

        setDataUser([depositData])
        setTotalUsers(totalUser)
    }

    //fetch data date by date
    const fetchData = (data) => {
        //data reset
        const dataResetChartDateByDate = [
            {
                _id: {
                    day: dayjs(endDate).date(),
                    month: dayjs(endDate).month() + 1,
                    year: dayjs(endDate).year(),
                },
                totalUser: 0,
            },
            {
                _id: {
                    day: dayjs(startDate).date(),
                    month: dayjs(startDate).month() + 1,
                    year: dayjs(startDate).year(),
                },
                totalUser: 0,
            },
        ]
        request
            .post(total_user_ref_from_date_to_date, { ...data })
            .then((res) => {
                const totalDateByDate = res.data.data
                console.log('data chart date by date', totalDateByDate)

                // hàm converChartDataDateByDate
                totalDateByDate.length > 0 ? convertChartDataDateByDate(totalDateByDate) : convertChartDataDateByDate(dataResetChartDateByDate)
            })
            .catch((err) => {
                console.error('Loi lay du lieu chart ref cua kols', err)
                message.warning('Lỗi cập nhật dữ liệu!')
                convertChartDataDateByDate(dataResetChartDateByDate)
            })
    }

    // Hàm bổ sung các tháng còn thiếu
    const fillMissingMonths = (data) => {
        const filledData = []
        let totalUsers = 0

        for (let i = 1; i <= 12; i++) {
            const monthData = data.find((item) => item._id === i)
            if (monthData) {
                filledData.push({ month: 'Tháng ' + i, 'Người giới thiệu': monthData.totalUser })
                totalUsers += monthData.totalUser
            } else {
                filledData.push({ month: 'Tháng ' + i, 'Người giới thiệu': 0 })
            }
        }

        return { filledData, totalUsers }
    }

    // Hàm bổ sung các ngày còn thiếu trong tháng
    const fillMissingDays = (data, month, year) => {
        const filledData = []
        const totalDaysInMonth = getDaysInMonth(month, year)

        for (let i = 1; i <= totalDaysInMonth; i++) {
            const dayData = data.find((item) => item._id.day === i)
            if (dayData) {
                // fix total chart chỉ nhận string ở trục x
                filledData.push({ day: String(i), 'Người giới thiệu': dayData.count })
            } else {
                filledData.push({ day: String(i), 'Người giới thiệu': 0 })
            }
        }

        return filledData
    }

    // Sử dụng hàm này sau khi fetch data theo tháng

    // Handle khi thay đổi năm
    const handleChangeYear = (value) => {
        const year = moment(value).year()
        setSelectedDate(value)
        fetchData(year, (type = 'year'))
    }

    // Handle khi thay đổi tháng
    const handleChangeMonth = (value) => {
        const monthYear = moment(value)
        setSelectedDate(monthYear)
        fetchData(monthYear.month(), (type = 'month'))
    }

    // fetching
    useEffect(() => {
        if (viewMode === 'month') {
            fetchData(selectedDate.month(), 'month')
        } else {
            fetchData(selectedDate.year(), 'year')
        }
    }, [viewMode, selectedDate])
    
    const { RangePicker } = DatePickerAntd
    const onRangeChange = (dates, dateStrings) => {
        if (dates) {
            setDateRange(dates)
            setStartDate(dates[0])
            setEndDate(dates[1])

            //data
            const data = {
                companyId: selectedCompanyId,
                startDate: dates[0],
                endDate: dates[1],
            }

            //fetch data date by date
            fetchData(data)
        } else {
            console.log('Clear')
            setDateRange([])
            setStartDate(null)
            setEndDate(null)
        }
    }

    const rangePresets = [
        {
            label: '7 ngày trước',
            value: [dayjs().add(-7, 'd'), dayjs()],
        },
        {
            label: '14 ngày trước',
            value: [dayjs().add(-14, 'd'), dayjs()],
        },
        {
            label: '30 ngày trước',
            value: [dayjs().add(-30, 'd'), dayjs()],
        },
        {
            label: '90 ngày trước',
            value: [dayjs().add(-90, 'd'), dayjs()],
        },
    ]
    console.log('dataUser', dataUser)
    return (
        <Box
            m='20px'
            sx={{
                '& .MuiTypography-root.MuiTypography-h2': {
                    margin: '0 0 5px 0',
                },
                '& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: colors.grey[100],
                    borderWidth: '1px',
                },
                '& .MuiInputLabel-root.Mui-focused': { color: colors.grey[100] },
                '& .MuiTypography-root.MuiTypography-h2': {
                    margin: '0 0 5px 0',
                },
                '& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: colors.grey[100],
                    borderWidth: '1px',
                },
                '& .MuiInputLabel-root.Mui-focused': { color: colors.grey[100] },
                '& .ant-picker-range-separator .ant-picker-separator .anticon.anticon-swap-right svg': {
                    color: colors.greenAccent[100],
                },
                '& .ant-picker .ant-picker-input >input': {
                    color: colors.grey[100],
                    fontSize: '13px',
                },
                '& .ant-picker-large .ant-picker-input>input::placeholder': {
                    color: colors.grey[100],
                    fontSize: '13px',
                },
                '& .ant-picker-suffix .anticon.anticon-calendar svg': {
                    color: colors.grey[100],
                },
                '& .ant-picker-clear .anticon.anticon-close-circle svg': {
                    color: colors.grey[100],
                },
            }}
        >
            <Box display='flex' justifyContent='space-between' alignItems='center'>
                <Header title='THỐNG KÊ NGƯỜI GIỚI THIỆU' subtitle='Trang thống kê số người đã giới thiệu' />
            </Box>

            <Box height='75vh' m='40px 0 0 0'>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: { xs: 'flex-start', md: 'space-between' },
                        flexDirection: { xs: 'column', md: 'row' },
                    }}
                >
                    <Box>
                        {/* DatePicker tương ứng với loại thống kê */}
                        {/* <LocalizationProvider
                            dateAdapter={AdapterMoment}
                            adapterLocale={moment.locale('vi')}
                            sx={{ width: { xs: '100%', sm: '320px' } }}
                        >
                            <Box>
                                {viewMode === 'year' ? (
                                    <DatePicker
                                        label='Chọn năm'
                                        views={['year']}
                                        value={selectedDate}
                                        onChange={handleChangeYear}
                                        sx={{ width: { xs: '100%', sm: '320px' } }}
                                    />
                                ) : (
                                    <DatePicker
                                        label='Chọn tháng'
                                        views={['month', 'year']}
                                        value={selectedDate}
                                        onChange={handleChangeMonth}
                                        sx={{ width: { xs: '100%', sm: '320px' } }}
                                    />
                                )}
                            </Box>
                        </LocalizationProvider> */}
                        <Box direction='vertical' size={24} sx={{ width: { xs: '100%', sm: '320px' } }}>
                                <RangePicker
                                    size='large'
                                    presets={rangePresets}
                                    onChange={onRangeChange}
                                    style={{ padding: '15px', backgroundColor: colors.primary[500] }}
                                    placeholder={['Ngày bắt đầu', 'Ngày kết thúc']}
                                    value={dateRange}
                                />
                            </Box>

                        {/* Nút chuyển đổi giữa thống kê theo tháng và năm */}
                        {/* <Box mt={2} display={'flex'} sx={{ width: { xs: '100%', sm: '320px' } }}>
                            <Button
                                sx={{ mr: '20px', fontWeight: 600, flex: { xs: 1, sm: 'auto' } }}
                                variant={viewMode === 'year' ? 'contained' : 'outlined'}
                                color='secondary'
                                onClick={() => setViewMode('year')}
                            >
                                Thống kê theo năm
                            </Button>
                            <Button
                                sx={{ fontWeight: 600, flex: { xs: 1, sm: 'auto' } }}
                                variant={viewMode === 'month' ? 'contained' : 'outlined'}
                                color='secondary'
                                onClick={() => setViewMode('month')}
                            >
                                Thống kê theo tháng
                            </Button>
                        </Box> */}
                    </Box>

                    <Box sx={{ marginTop: { xs: '10px', md: 0 } }}>
                        {/* <Box mr={'150px'} fontWeight={600} color={colors.greenAccent[500]}>
                            TỔNG SỐ Hoa hồng:{' '}
                            <Typography fontWeight={700} display='inline-block' variant='h4'>
                                2300
                            </Typography>
                        </Box> */}
                        <Box mr={'150px'} fontWeight={600} color={colors.grey[100]}>
                            TỔNG SỐ NGƯỜI ĐÃ GIỚI THIỆU TRONG {viewMode === 'month' ? `THÁNG ${selectedDate.month() + 1}` : `NĂM ${selectedDate.year()}`}:{' '}
                            <Typography fontWeight={700} display='inline-block' variant='h4' color={colors.greenAccent[500]}>
                                {totalUsers}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                {/* Biểu đồ */}
                {/* {dataUser && <BarChart nameChart='StatisticsUser' data={dataUser} type={viewMode} selectedDate={selectedDate} />} */}
                {dataUser.length > 0 && (
                    <LineChart
                        dataChart={dataUser}
                        nameChart='statisticsUser'
                        companyName={''}
                        selectedDate={dayjs(startDate).format('DD/MM/YYYY') + ' - ' + dayjs(endDate).format('DD/MM/YYYY')}
                    />
                )}
            </Box>
        </Box>
    )
}

export default KOLsStatisticsUser
