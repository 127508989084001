import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Form, Input } from "antd";
import { MdEmail, MdLock, MdPhone } from "react-icons/md";
import { Box, Button, Typography, useTheme } from "@mui/material";
import {
  login,
  kol_signin_by_phone,
  update_profile,
  is_kol_phone_number,
} from "../../utils/api/apiList";
import { auth } from "../../firebase/setup";
import { signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth";
import { BsFillShieldLockFill, BsTelephoneFill } from "react-icons/bs";
import { SiKeepassxc } from "react-icons/si";
import { CgSpinner } from "react-icons/cg";
import { message } from "antd";
import { phoneRegExp } from "../../components/prefixData";
import { tokens } from "../../theme";
import request, { requestLogin } from "../../utils/api/request";
import SecurityUpdateGoodIcon from "@mui/icons-material/SecurityUpdateGood";
import OtpInput from "otp-input-react";
import EmailIcon from '@mui/icons-material/Email';
import moment from "moment";
import "react-phone-input-2/lib/style.css";
import "./style.scss";
//style
const backIconStyle = {
  cursor: "pointer",
  position: "absolute",
  top: 16,
  left: 16,
  color: "#B0B0B0", // light gray color
  opacity: 0.5, // opacity for disabled effect
  transition: "color 0.3s ease", // smooth transition for color change
};
const backIconHoverStyle = {
  color: "#808080", // darker gray color on hover
  opacity: 0.7, // slightly less transparent on hover
};

function Login() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const colors = tokens(theme.palette.mode);
  const [errMess, setErrMess] = useState(false);
  const [errPhone, setErrPhone] = useState(false);
  const [errVerify, setErrVerify] = useState(false);
  const [formMissPass, setFormMissPass] = useState(false);
  const [openBoxOtp, setOpenBoxOtp] = useState(false);
  const [openBoxChangePassword, setOpenBoxChangePassword] = useState(false);
  const [otp, setOtp] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessageVerify, setErrorMessageVerify] = useState(null);
  const [stateSignIn, setStateSignin] = useState(null);
  const [countdown, setCountdown] = useState(0);
  const [countdownOTP, setCountdownOTP] = useState(0);
  const [isActivePhone, setIsActivePhone] = useState(true);
  const [expiredDate, setExpiredDate] = useState("");
  const [loginWithPhone, setLoginWithPhone] = useState(false);
  const [isForgotPass, setIsForgotPass] = useState(false);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //form
  const formOTP = useRef(null);
  const formVerify = useRef(null);
  const formChangePass = useRef(null);

  //token
  const tokenStorage = localStorage.getItem("token");

  //mess
  const [messageApi, messageContextHolder] = message.useMessage();

  useEffect(() => {
    if (tokenStorage && tokenStorage.length > 0) {
      navigate("/");
    }
  }, [tokenStorage, navigate]);

  //function
  const onFinish = async (values) => {
    const { username, password } = values;
    const data = {
      username,
      password,
    };

    requestLogin
      .post(login, data)
      .then(async (response) => {
        const token = response.data.data.token;
        const role = response.data.data.user.role;
        console.log("response", response.data);

        if (role === 69) {
          localStorage.setItem("role", "admin");
          localStorage.setItem("token", token);
          localStorage.setItem("userId", response.data.data.user._id);
          window.location.href = "/";
        } else {
          setErrMess(true);
        }
      })
      .catch((error) => {
        setErrMess(true);
      });
  };

  const handleSubmit = () => {
    form.submit();
  };

  const handleMissPass = () => {
    setFormMissPass(true);
    setOpenBoxOtp(false);
    setIsForgotPass(true);
  };

  const handleBack = () => {
    if (openBoxOtp) {
      setOpenBoxOtp(false);
    } else if (formMissPass) {
      setFormMissPass(false);
    }
  };

  //OTP
  function onCaptchVerify() {
    if (!window.recaptchaVerifier) {
      try {
        window.recaptchaVerifier = new RecaptchaVerifier(
          auth,
          "recaptcha-container",
          {
            size: "invisible",
            callback: (response) => {
              // callback không cần thiết cho OTP, chỉ cần xác nhận reCAPTCHA
            },
            "expired-callback": () => {
              console.log("reCAPTCHA expired");
            },
          }
        );
      } catch (err) {
        console.error("Error creating reCAPTCHA verifier:", err);
        setLoading(false);
        return;
      }
    }
  }

  const handleSendOtp = () => {
    setLoading(true);
    onCaptchVerify();

    const appVerifier = window.recaptchaVerifier;
    const formatPh = "+84" + phoneNumber.slice(1);
    console.log("handle send", formatPh);

    //check phone
    request
      .post(is_kol_phone_number, { phoneNumber })
      .then((res) => {
        setIsActivePhone(true);
        // send otp
        signInWithPhoneNumber(auth, formatPh, appVerifier)
          .then((confirmationResult) => {
            window.confirmationResult = confirmationResult;
            setLoading(false);
            setOpenBoxOtp(true);
            setCountdownOTP(30);
            const countdownInterval = setInterval(() => {
              setCountdownOTP((prevCountdown) => {
                if (prevCountdown === 1) {
                  clearInterval(countdownInterval);
                  setRerequestOTP(true);
                }
                return prevCountdown - 1;
              });
            }, 1000);
            message.success("Gửi OTP thành công!");
          })
          .catch((error) => {
            console.log("Error during signInWithPhoneNumber:", error);
            setLoading(false);
            setErrPhone(true);
            setPhoneNumber(null);
            message.error("Gửi OTP thất bại!");
          });
      })
      .catch((err) => {
        console.log(err);
        message.warning("Số điện thoại chưa được đăng ký");
        setIsActivePhone(false);
        setLoading(false);
      });
  };

  const onFinishOTP = (values) => {
    const { phoneNumber } = values;
    console.log('values', values, phoneNumber, values.phoneNumber)
    setPhoneNumber(phoneNumber);
    handleSendOtp();
  };

  //verify
  const [rerequestOTP, setRerequestOTP] = useState(false);
  const onOTPVerify = (otp) => {
    if (countdownOTP <= 0) {
      message.error("OTP đã hết hạn!");
      formVerify.current.resetFields();
      return;
    }
    setLoading(true);
    window.confirmationResult
      .confirm(otp)
      .then(async (res) => {
        const phoneNumber = res.user.phoneNumber;
        const formatPhone = 0 + phoneNumber.slice(3);
        setLoading(false);

        // Additional logic after successful OTP verification
        request
          .post(kol_signin_by_phone, {
            phoneNumber: formatPhone,
          })
          .then((res) => {
            console.log(res);
            message.success("xác thực thành công!");
            setRerequestOTP(false);
            if (!isForgotPass) {
              setLoginWithPhone(false)
              const token = res.data.data.token;
              const role = res.data.data.user.role;
              console.log("response", res.data);

              if (role === 69) {
                localStorage.setItem("role", "admin");
                localStorage.setItem("token", token);
                localStorage.setItem("userId", res.data.data.user._id);
                window.location.href = "/";
              } else {
                setErrMess(true);
              }
              setStateSignin(res.data.data);
            } else {
              setOpenBoxChangePassword(true);
              setIsForgotPass(false);
            }

            setOpenBoxOtp(false);
            setFormMissPass(false);
            setPhoneNumber(formatPhone);
            setStateSignin(res.data.data);

          })
          .catch((err) => {
            console.log(err);
            const messErr = err.response.data.data;
            setErrVerify(true);
            switch (messErr) {
              case "UserNotFound":
                message.error(
                  "số điện thoại chưa được đăng kí, vui lòng thử lại!"
                );
                setErrorMessageVerify("Số điện thoại chưa được đăng kí");
                break;
              default:
                message.error("có lỗi chưa xác định! vui lòng thử lại");
                setErrorMessageVerify("có lỗi chưa xác định! vui lòng thử lại");
                break;
            }
          });
      })
      .catch((err) => {
        console.log(err);
        message.error("Mã OTP không đúng");
        setErrVerify(true);
        setErrorMessageVerify("Mã OTP không đúng, nhập lại!");
        setLoading(false);
        formVerify.current.resetFields();
      });
  };

  const onFinishVerify = () => {
    onOTPVerify(otp);
  };

  //change passs and login
  const onChangePassAndLogin = (values) => {
    console.log("stateSignIn", stateSignIn);

    formChangePass.current
      ?.validateFields()
      .then((values) => {
        const data = {
          userInfo: {
            password: values.newPassword,
          },
        };
        const role = stateSignIn.user.role;
        const token = stateSignIn.token;

        request.setAuthToken(token);
        request
          .post(update_profile, data)
          .then((res) => {
            console.log(res);
            message.success(
              "Đổi mật khẩu thành công, chuyển hướng đăng nhập sau 3s!"
            );
            // const companyId = response.data.data?.user?.fromCompany
            let counter = 3;
            setCountdown(counter);
            const interval = setInterval(() => {
              counter -= 1;
              setCountdown(counter);
              if (counter === 0) {
                clearInterval(interval);
                // Navigate or redirect
                if (role === 69) {
                  localStorage.setItem("role", "admin");
                  localStorage.setItem("token", token);
                  window.location.href = "/";
                } else {
                  setErrMess(true);
                }
              }
            }, 1000);
          })
          .catch((err) => {
            console.error(err);
            console.log("Đổi mật khẩu thất bại");
          });
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const validateEmailOrPhone = (rule, value) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phonePattern = phoneRegExp;

    if (!value) {
      return Promise.reject();
    }

    if (!emailPattern.test(value) && !phonePattern.test(value)) {
      return Promise.reject();
    }

    return Promise.resolve();
  };

  const openLoginWithPhone = () => {
    setLoginWithPhone(true);

  };

  return (
    <div className="login-page layout bg-green">
      <div id="recaptcha-container"></div>
      <Row justify="center" className="row-form row-login">
        {messageContextHolder}
        {!formMissPass &&
          !openBoxOtp &&
          !openBoxChangePassword &&
          !loginWithPhone && (
            <div className="col-form col-login m-auto">
              <div className="div-form-title">
                <p className="text-2xl font-medium text-center text-gray800">
                  Đăng Nhập
                </p>
              </div>
              <div>
                {errMess && (
                  <div style={{ color: "red" }}>
                    Email/password is incorrect
                  </div>
                )}
                <Form name="login" form={form} onFinish={onFinish}>
                  <Form.Item
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng nhập số điện thoại hoặc email!",
                      },
                      {
                        validator: validateEmailOrPhone,
                        message:
                          "Định dạng số điện thoại hoặc email không hợp lệ!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Số điện thoại hoặc email"
                      prefix={<MdEmail size={20} />}
                    />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng nhập mật khẩu!",
                      },
                      // {
                      //     min: 8,
                      //     message: 'Mật khẩu tối thiểu 8 ký tự!',
                      // },
                      // {
                      //     pattern: /^(?=.*[a-zA-Z])(?=.*\d).{8,}$/,
                      //     message: 'Mật khẩu phải bao gồm cả chữ cái và số!',
                      // },
                    ]}
                  >
                    <Input.Password
                      placeholder="Mật khẩu"
                      prefix={<MdLock size={20} />}
                    />
                  </Form.Item>
                  <div className="mb-4 font-13 text-right text-primary_g hover:text-medium">
                    <Button onClick={handleMissPass}>Quên mật khẩu</Button>
                  </div>
                  <Form.Item name="loginresult">
                    <Button
                      fullWidth
                      type="submit"
                      variant="contained"
                      className="form-btn"
                    >
                      Đăng Nhập
                    </Button>
                  </Form.Item>
                </Form>
                <Typography
                  color={colors.grey[400]}
                  sx={{ textAlign: "center", padding: "10px 0 20px 0" }}
                >
                  hoặc đăng nhập bằng
                </Typography>
                <Button
                  fullWidth
                  variant="outlined"
                  sx={{
                    fontSize: "14px",
                    borderColor: colors.grey[100],
                    padding: "7px 15px",
                    " &:hover": {
                      backgroundColor: "white",
                    },
                  }}
                  onClick={openLoginWithPhone}
                >
                  <SecurityUpdateGoodIcon
                    sx={{ marginRight: "5px", color: "#605e5e" }}
                  />
                  Số điện thoại
                </Button>
              </div>
            </div>
          )}
        {loginWithPhone && !openBoxOtp && (
          <div className="col-form col-login m-auto">
            <div className="div-form-title">
              <p className="text-2xl font-medium text-center text-gray800">
                Đăng nhập qua OTP
              </p>
            </div>
            <div>
              {errMess && (
                <div style={{ color: "red" }}>Email/password is incorrect</div>
              )}
              <Form name="loginOTP" form={form} onFinish={onFinishOTP}>
                <Form.Item
                  name="phoneNumber"
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập số điện thoại!",
                    },
                    {
                      pattern: /^[0-9]{10}$/,
                      message: "Số điện thoại phải có 10 chữ số!",
                    },
                  ]}
                >
                  <Input
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    placeholder="Phone"
                    prefix={<MdPhone size={20} />}
                  />
                </Form.Item>
                <Form.Item name="loginresult">
                  <Button fullWidth type="submit" variant="contained" className="form-btn" >Tiếp tục</Button>
                </Form.Item>
              </Form>
              <Typography color={colors.grey[400]} sx={{ textAlign: "center", padding: "10px 0 20px 0" }} >hoặc đăng nhập bằng</Typography>
              <Button
                fullWidth
                type="submit"
                variant="outlined"
                sx={{
                  fontSize: "14px",
                  borderColor: colors.grey[100],
                  padding: "7px 15px",
                  " &:hover": {
                    backgroundColor: "white",
                  },
                }}
                onClick={() => setLoginWithPhone(false)}
              >
                <EmailIcon sx={{ marginRight: "5px", color: "#605e5e" }} />
                Email
              </Button>
            </div>
          </div>
        )}
        {formMissPass && !openBoxOtp && (
          <div className="col-form col-login m-auto">
            <div className="div-form-title">
              <p className="text-2xl font-medium text-center text-gray800">Lấy lại mật khẩu</p>
            </div>
            <div>
              {errPhone && (
                <div style={{ color: "red" }}>Có lỗi, vui lòng thử lại!</div>
              )}
              {!isActivePhone && (
                <div style={{ color: "orange" }}>Số điện thoại chưa được đăng ký!</div>
              )}
              <Form name="phone" ref={formOTP} onFinish={onFinishOTP}>
                <Form.Item
                  name="phoneNumber"
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập số điện thoại!",
                    },
                    {
                      pattern: /^[0-9]{10}$/,
                      message: "Số điện thoại phải có 10 chữ số!",
                    },
                  ]}
                >
                  <Input
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    placeholder="Phone"
                    prefix={<MdPhone size={20} />}
                  />
                </Form.Item>
                <Form.Item name="sendotp">
                  <Button
                    id="send-otp-button"
                    fullWidth
                    variant="contained"
                    className="form-btn"
                    type="submit"
                  // onClick={onFinishOTP}
                  >
                    {loading && (
                      <CgSpinner size={20} style={{ marginRight: "5px" }} className="mt-1 animate-spin" />
                    )}
                    Gửi OTP
                  </Button>
                </Form.Item>
              </Form>
              <Box sx={{ textAlign: "right" }}>
                <Button onClick={handleBack}>Quay lại</Button>
              </Box>
            </div>
          </div>
        )}
        {openBoxOtp && (
          <div className="col-form col-login m-auto">
            <div style={{ marginBottom: "20px" }}>
              <p className="text-2xl font-medium text-center text-gray800">
                Xác thực tài khoản
              </p>
              <p style={{ margin: "20px 10px 0 10px", textAlign: "center" }}>
                Nhập mã xác thực OTP gồm 6 chữ số được gửi về số điện thoại{" "}
                {phoneNumber} mà bạn đã đăng ký
              </p>
            </div>
            <div>
              {errVerify && (
                <div style={{ color: "red" }}>{errorMessageVerify}</div>
              )}
              <Form
                name="otp"
                ref={formVerify}
                onFinish={onFinishVerify}
                style={{ display: "flex", flexDirection: "column" }}
              >
                {countdownOTP > 0 && (
                  <p>OTP có hiệu lực trong: {countdownOTP} giây</p>
                )}
                {rerequestOTP && !countdownOTP && (
                  <Button onClick={handleSendOtp}>Gửi lại</Button>
                )}
                <Form.Item
                  name="OTP"
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập OTP!",
                    },
                    {
                      validator: (_, value) => {
                        if (value && value.length !== 6) {
                          return Promise.reject(
                            new Error("OTP phải có 6 chữ số!")
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <OtpInput
                    value={otp}
                    onChange={(newValue) => {
                      setOtp(newValue);
                    }}
                    OTPLength={6}
                    otpType="number"
                    disabled={false}
                    autoFocus
                    className="opt-container "
                  ></OtpInput>
                </Form.Item>
                <Form.Item name="verifyotp">
                  <Button
                    fullWidth
                    variant="contained"
                    className="form-btn"
                    type="submit"
                  >
                    {loading && (
                      <CgSpinner size={20} className="mt-1 animate-spin" />
                    )}
                    Xác Thực
                  </Button>
                </Form.Item>
                <Button
                  style={{ marginLeft: "auto", color: "#6868af" }}
                  onClick={() => {
                    setFormMissPass(false);
                    setOpenBoxOtp(false);
                    setErrVerify(false);
                    setErrPhone(false);
                    setErrMess(false);
                    setOtp(null);
                    setPhoneNumber(null);
                  }}
                >
                  về trang đăng nhập
                </Button>
              </Form>
            </div>
          </div>
        )}
        {openBoxChangePassword && (
          <div className="col-form col-login m-auto">
            <div>
              <p className="text-2xl font-medium text-center text-gray800">
                Đổi mật khẩu
              </p>
              <div className="bg-white text-emerald-500 w-fit mx-auto p-4 rounded-full">
                <SiKeepassxc size={30} />
              </div>
            </div>
            <div>
              {countdown > 0 && <p>Chuyển hướng sau: {countdown} giây</p>}
              <Form
                name="formChangePass"
                ref={formChangePass}
                style={{ display: "flex", flexDirection: "column" }}
                onFinish={onChangePassAndLogin}
              >
                <Form.Item
                  name="newPassword"
                  // label='Mật khẩu mới'
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập mật khẩu!",
                    },
                    {
                      pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
                      message:
                        "Mật khẩu tối thiểu là 8 ký tự bao gồm chữ và số!",
                    },
                  ]}
                  hasFeedback
                >
                  <Input.Password
                    placeholder="Mật khẩu mới"
                    prefix={<MdLock size={20} />}
                  />
                </Form.Item>
                <Form.Item
                  name="verifyNewPassword"
                  // label='xác thực mật khẩu'
                  dependencies={["newPassword"]}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập mật khẩu!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("newPassword") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("Mật khẩu không trùng khớp!")
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    placeholder="Xác thực mật khẩu"
                    prefix={<MdLock size={20} />}
                  />
                </Form.Item>
                <Form.Item name="verifyotp">
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    className="form-btn"
                  >
                    {loading && (
                      <CgSpinner size={20} className="mt-1 animate-spin" />
                    )}
                    Đăng nhập
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        )}
      </Row>
      {/* <Dialog open={open} onClose={handleClose} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
                <DialogTitle id='alert-dialog-title' sx={{ fontSize: '16px' }}>
                    {'Tài khoản bạn đăng ký đang có vấn đề'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>
                        Chúng tôi nhận thấy tài khoản mà doanh nghiệp bạn sở hữu đang hết hạn và cần được gia hạn để không bị gián đoạn khi sử dụng.
                    </DialogContentText>
                    <DialogContentText id='alert-dialog-description'>
                        Mọi thông tin xin liên hệ email
                        <Link
                            underline='hover'
                            sx={{
                                margin: '0 2px',
                                color: '#0f9875',
                                '&:hover': {
                                    cursor: 'pointer',
                                    color: '#10daa6',
                                },
                            }}
                            target='_blank'
                            href='https://mail.google.com/mail/?view=cm&fs=1&to=hotro@eclean.vn&su=Hỗ+trợ+gia+hạn+gói+cho+doanh+nghiêp'
                        >
                            hotro@eclean.vn
                        </Link>
                        hoặc số điện thoại
                        <Link
                            underline='hover'
                            sx={{
                                margin: '0 2px',
                                color: '#0f9875',
                                '&:hover': {
                                    cursor: 'pointer',
                                    color: '#10daa6',
                                },
                            }}
                        >
                            0963351545
                        </Link>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Từ chối</Button>
                    <Button onClick={handleClose} autoFocus>
                        Chấp nhận
                    </Button>
                </DialogActions>
            </Dialog> */}
    </div>
  );
}

export default Login;
