import { configureStore } from '@reduxjs/toolkit'
import usersSlice from '../scenes/users/usersSlice'
import taskBookingSlice from '../scenes/workflowManagement/taskBooking/taskBookingSlice'
import getEmloyeeSlice from './slice/getEmployeeSlice'
import getEventNewOrderSlice from './slice/getNewOrderSlice'
import getEventUploadAvatarSlice from './features/getEventUploadAvatarSlice'
import getEventUpdateCleanSlice from './features/getEventUpdateCleanSlice'
import getEventUpdateTaskBookingSlice from './features/getEventUpdateTaskBookingSlice'
import getEventUpdatePermanentTaskSlice from './features/getEventUpdatePermanentTaskSlice'
import getEventAdminCreateTaskSlice from './features/getEventAdminCreateTaskSlice'
import getEventAdminCreateCleanSlice from './features/getEventAdminCreateCleanSlice'
import getEventUpdateProfileAdminSlice from './features/getEventUpdateProfileAdminSlice'
import getEventAdminCreatePermanentSlice from './features/getEventAdminCreatePermanentSlice'
import getEventAddPartnerPermanentSlice from './features/getEventAddPartnerPermanentSlice'

const store = configureStore({
    reducer: {
        // users: usersSlice.reducer,
        taskBooking: taskBookingSlice.reducer,
        getEmployeeData: getEmloyeeSlice.reducer,
        getEventNewOrder: getEventNewOrderSlice.reducer,
        getEventUploadAvatar: getEventUploadAvatarSlice.reducer,
        getEventUpdateClean: getEventUpdateCleanSlice.reducer,
        getEventUpdateTaskBooking: getEventUpdateTaskBookingSlice.reducer,
        getEventUpdatePermanentTask: getEventUpdatePermanentTaskSlice.reducer,
        getEventAdminCreateTask: getEventAdminCreateTaskSlice.reducer,
        getEventAdminCreateClean: getEventAdminCreateCleanSlice.reducer,
        getEventAdminCreatePermanent: getEventAdminCreatePermanentSlice.reducer,
        getEventUpdateProfileAdmin: getEventUpdateProfileAdminSlice.reducer,
        getEventAddPartnerPermanent: getEventAddPartnerPermanentSlice.reducer,
    },
})

export default store
