import { Box, Button, Popover, TextField, Typography, useTheme } from '@mui/material'
import { tokens } from '../../theme'
import { memo, useState } from 'react'

function PopoverComponent({ type, idRow, anchorEl, onClose, handleConfirm, boxName }) {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    const [note, setNote] = useState('')

    const styleButton = {
        bgcolor: type === 'cancel' || type === 'delete' ? '#d40000' : '#55a53d',
        color: 'white',
        fontWeight: '600',
        mr: '20px',
        '&:hover': {
            bgcolor: type === 'cancel' || type === 'delete' ? 'red' : '#6ec354',
        },
    }

    const handleClose = () => {
        onClose()
        setNote('')
    }

    return (
        <Popover
            id={idRow}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            sx={{
                '& .MuiPaper-root': {
                    boxShadow: 'rgba(149, 152, 165, 0.2) 2px 2px 2px',
                    border: '1px solid',
                    boxShadow: 'none',
                },
                '& .Mui-focused fieldset': {
                    borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.23) !important' : 'rgba(0, 0, 0, 0.23) !important',
                    borderWidth: '1px !important',
                },
                '& .MuiFormLabel-root.Mui-focused': {
                    color: colors.grey[100],
                },
            }}
        >
            <Box p={2}>
                <Typography>
                    {(() => {
                        switch (type) {
                            case 'cancel':
                                switch (boxName) {
                                    case 'withDrawal':
                                        return 'Từ chối yêu cầu rút?'
                                    default:
                                        return 'Bạn muốn huỷ đơn hàng này?'
                                }
                            case 'delete':
                                switch (boxName) {
                                    case 'banner':
                                        return 'Bạn muốn xoá banner này?'
                                    case 'deleteCitySetting':
                                        return 'Bạn muốn xoá thành phố này?'
                                    case 'package':
                                        return 'Bạn muốn xoá gói này?'
                                    case 'partner':
                                        return 'Bạn muốn xoá nhân viên này?'
                                    case 'companyManangenment':
                                        return 'Bạn muốn xoá tài khoản khoản công ty này?'
                                    case 'notification':
                                        return 'Bạn muốn xoá thông báo này?'
                                    default:
                                        return 'Bạn muốn xoá đơn hàng này?'
                                }
                            case 'accept':
                                switch (boxName) {
                                    case 'withDrawal':
                                        return 'Chấp nhận yêu cầu rút?'
                                    case 'permanentTask':
                                        return 'Thanh toán đơn hàng?'

                                    default:
                                        break
                                }
                            default:
                                break
                        }
                    })()}
                </Typography>
                <Box
                    sx={{
                        mt: '10px',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        flexDirection: 'column',
                    }}
                >
                    {type !== 'accept' && boxName !== 'permanentTask' && type !== 'delete' && (
                        <TextField
                            id='outlined-multiline-static'
                            label='Nhập lý do'
                            multiline
                            rows={3}
                            value={note}
                            onChange={(e) => setNote(e.target.value)}
                            sx={{ mb: '20px' }}
                        />
                    )}
                    <Box display='flex' justifyContent='flex-end'>
                        <Button
                            size='small'
                            variant='contained'
                            onClick={() => {
                                setNote('')
                                handleConfirm(note)
                            }}
                            sx={{
                                marginRight: '10px',
                                fontWeight: 600,
                                color: colors.grey[100],
                                ...styleButton,
                            }}
                        >
                            OK
                        </Button>
                        <Button
                            size='small'
                            onClick={handleClose}
                            variant='outlined'
                            sx={{
                                bgcolor: 'white',
                                '&:hover': {
                                    bgcolor: '#e8e8e8c4',
                                },
                            }}
                        >
                            Huỷ
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Popover>
    )
}

export default memo(PopoverComponent)
