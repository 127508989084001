import { createSlice } from '@reduxjs/toolkit'

const getEventUpdatePermanentTaskSlice = createSlice({
    name: 'getEventUpdatePermanentTask',
    initialState: {
        data: null,
    },
    reducers: {
        eventUpdatePermanentTask: (state, action) => {
            state.data = action.payload
            console.log(action)
        },
    },
})

export default getEventUpdatePermanentTaskSlice
