import io from 'socket.io-client'
import { baseURL } from './baseURL'

const adminId = localStorage.getItem('userId')

const connectSocket = () => {
    return io.connect(baseURL, {
        query: {
            user: JSON.stringify({ userId: adminId }),
        },
        autoConnect: true,
        reconnection: true,
        reconnectionAttempts: 5,
        reconnectionDelayMax: 10000,
    })
}

export default connectSocket
