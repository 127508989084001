import { useState, useEffect } from "react";
import { Box, useTheme, Typography } from "@mui/material";
import Header from "@components/Header";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { total_revenue_by_bonus_point_by_month_by_year } from "@utils/api/apiList";
import { message } from "antd";
import { tokens } from "@src/theme";
import moment from "moment";
import request from "@utils/api/request";
import LineChart from "@components/LineChart";
import BarChart from "@components/BarChart";
import "moment/locale/vi";

const RevenueChart = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [dataUser, setDataUser] = useState(null);
  const [selectedDate, setSelectedDate] = useState(moment());

  const fetchDataChart = () => {
    request
      .post(total_revenue_by_bonus_point_by_month_by_year)
      .then((res) => {
        console.log("data user", res);
        const { filledData } = fillMissingMonths(res.data.data);
        setDataUser(filledData);
        console.log(filledData);
      })
      .catch((err) => {
        message.error("Lỗi dữ liệu!");
        console.error("err", err);
        setDataUser(dataUser);
      });
  };
  // Hàm bổ sung các tháng còn thiếu
  const fillMissingMonths = (data) => {
    const filledData = [];
    let totalRevenue = 0;

    for (let i = 1; i <= 12; i++) {
      const monthData = data.find((item) => item._id === i);
      if (monthData) {
        filledData.push({
          month: "" + i,
          "Doanh thu": monthData.totalBonus / 1000000,
        });
        totalRevenue += monthData.totalBonus;
        setTotalRevenue(totalRevenue)
      } else {
        filledData.push({ month: "" + i, "Doanh thu": 0 });
      }
    }

    return { filledData, totalRevenue };
  };

  useEffect(() => {
    fetchDataChart();
  }, []);
  return (
    <>
      <Typography
        variant="h3"
        p="0 30px"
        fontWeight="700"
        color={colors.greenAccent[500]}
      >
        {Intl.NumberFormat("en-US").format(totalRevenue)} Điểm
      </Typography>
      {!!dataUser && dataUser.length > 0 && (
        <Box p={"30px"} height="400px">
          <BarChart
            nameChart="revenueKols"
            data={dataUser}
            selectedDate={selectedDate}
          />
        </Box>
      )}
    </>
  );
};

export default RevenueChart;
