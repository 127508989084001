import React, {useEffect, useState} from 'react'
import SidebarComponent from '../../scenes/global/SideBar'
import Topbar from '../../scenes/global/Topbar'

function AdminLayout({ children }) {
    const [isMounted, setIsMounted] = useState(false)
    const [isMobile, setIsMobile] = useState(false)
    const [openMenu, setOpenMenu ] = useState(false)
    //#region đoạn này dùng để check moble view
    useEffect(() => {
        setIsMounted(true);
        const handleWindowSizeChange = () => {
            setIsMobile(window.innerWidth <= 1024)
        }

        if (typeof window !== 'undefined') {
            handleWindowSizeChange()
            window.addEventListener('resize', handleWindowSizeChange)
            return () => {
                window.removeEventListener('resize', handleWindowSizeChange)
            }
        }
    }, [])

    // render loading cho đến khi component được mount
    if (!isMounted) return;
    //#endregion
    return (
        <>
            <SidebarComponent isMobile={isMobile} collapsed={openMenu} onCollapsed={() => setOpenMenu(!openMenu)}/>
            <main className='content' style={{ overflowY: 'auto' }}>
                <Topbar isMobile={isMobile} toggleMenu={() => setOpenMenu(true)}/>
                {children}
            </main>
        </>
    )
}

export default AdminLayout
