import { Box, IconButton, Typography, Menu, MenuItem, Tooltip, InputBase, useTheme } from '@mui/material'
import React, { useEffect, useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ColorModeContext, tokens } from '../../theme'
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined'
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined'
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined'
import SearchIcon from '@mui/icons-material/Search'
import LogoutIcon from '@mui/icons-material/Logout'
import MenuIcon from '@mui/icons-material/Menu'
import { SettingFilled } from '@ant-design/icons'
import { Settings } from '@mui/icons-material'
import { routerLinks } from '../../routes/constant'

function Topbar({ isMobile, toggleMenu }) {
    const theme = useTheme()
    const colorMode = useContext(ColorModeContext)
    const [anchoElMenu, setAnchorElMenu] = useState(null)
    const [openSubmenu, setOpenSubmenu] = useState(false)

    const role = localStorage.getItem('role')
    const navigate = useNavigate()

    //menu
    const handleProfileMenuOpen = (event) => {
        setAnchorElMenu(event.currentTarget)
        setOpenSubmenu(false)
    }
    const handleMenuClose = () => {
        setAnchorElMenu(null)
    }

    //logout
    const handleLogout = () => {
        localStorage.removeItem('token')
        localStorage.removeItem('role')
        window.location.href = '/login'
    }

    const menuId = 'primary-search-account-menu'
    const renderMenu = (
        <Menu
            anchorEl={anchoElMenu}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={menuId}
            keepMounted
            open={Boolean(anchoElMenu)}
            onClose={handleMenuClose}
            sx={{
                '& .MuiList-root': { width: '240px' },
                '& .MuiCollapse-wrapperInner .MuiMenuItem-root': { pl: '50px' },
            }}
        >
            <MenuItem onClick={handleLogout}>
                <IconButton>
                    <LogoutIcon />
                </IconButton>
                <Typography>Đăng xuất</Typography>
            </MenuItem>
        </Menu>
    )

    // //profile admin
    // const handleOpenProfileAdmin = (e) => {
    //     setAnchorElProfile(e.currentTarget)
    // }

    // const handleCloseProfileAdmin = () => {
    //     setAnchorElProfile(null)
    // }

    // const handleConfirmProfile = (values,{resetForm}) => {

    // }

    return (
        <Box
            display='flex'
            justifyContent='space-between'
            p={2}
            sx={{
                top: 0,
                // position:isMobile ? 'sticky': 'unset',
                backgroundColor: theme.palette.mode === 'dark' ? '#131b2d' : '#fcfcfc',
                // boxShadow: scrolled ? '0 2px 2px 0 #cccccc59' : 'none',
                zIndex: 2,
                marginLeft: '4px'
            }}
        >
            {isMobile ? (
                <IconButton onClick={toggleMenu}>
                    <MenuIcon />
                </IconButton>
            ) : null}
            {/* Search bar */}
            {/* <Box display='flex' backgroundColor={colors.primary[400]} borderRadius='3px'>
                <InputBase sx={{ ml: 2, flex: 1 }} placeholder='Search' />
                <IconButton type='button' sx={{ p: 1 }}>
                    <SearchIcon />
                </IconButton>
            </Box> */}

            {/* ICONS */}
            <Box display='flex' ml={'auto'}>
                <IconButton onClick={colorMode.toggleColorMode}>
                    {theme.palette.mode === 'dark' ? <DarkModeOutlinedIcon /> : <LightModeOutlinedIcon />}
                </IconButton>
                <Box sx={{ flexGrow: 0 }}>
                    <Tooltip title='Cài đặt'>
                        <IconButton onClick={handleProfileMenuOpen} aria-controls={menuId} aria-haspopup='true'>
                            <PersonOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>
            {renderMenu}
        </Box>
    )
}

export default Topbar
