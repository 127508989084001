import { useState, useCallback } from 'react';

function useQueryOptions(initialOptions = { createdDate: -1 }) {
  const [queryOptions, setQueryOptions] = useState(initialOptions);

  const handleSortModelChange = useCallback((sortModel) => {
    if (sortModel.length > 0) {
      const sortField = sortModel[0].field;
      const sortOrder = sortModel[0].sort === "asc" ? 1 : -1;
      setQueryOptions({ [sortField]: sortOrder });
    } else {
      setQueryOptions(initialOptions);
    }
  }, [initialOptions]);

  return [queryOptions, handleSortModelChange];
}

export default useQueryOptions;
