import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Box, useTheme, Chip } from '@mui/material'
import { tokens } from '@src/theme'
import { kol_get_withdrawal_request } from '@utils/api/apiList'
import { message } from 'antd'
import BoxDelete from '@components/popover'
import Header from '@components/Header'
import StyleDataGrid from '@assets/styles/styleDataGrid'
import request from '@utils/api/request'
import moment from 'moment'
import DataGridCustom from '@src/components/datagrid'
import useQueryOptions from '@src/components/datagrid/useQueryOptions'

const KOLsWithdrawalsHistory = () => {
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)
  const role = localStorage.getItem('role')
  const [dataForTable, setDataForTable] = useState(null)

  //message
  const [messageApi, messageContextHolder] = message.useMessage()

  const [loading, setLoading] = useState(false)
  // pagination server side
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  })
  const [totalRows, setTotalRows] = useState(0)
  //sort server
  const [queryOptions, handleSortModelChange] = useQueryOptions({
    createdAt: -1,
  });

  //fetching
  useEffect(() => {
    fetchData()
  }, [paginationModel, queryOptions])

  //fetch data
  const fetchData = () => {
    setLoading(true)

    request
      .post(kol_get_withdrawal_request, {
        page: paginationModel.page + 1,
        limit: paginationModel.pageSize,
        sort: queryOptions,
      })
      .then((res) => {
        const data = res.data.data
        const onlyRequestData = !!data && data?.length > 0 && data.filter((item) => item.status === 1)
        const dataConvert =
          !!onlyRequestData &&
          onlyRequestData?.length > 0 &&
          onlyRequestData
            .map((elm, index) => {
              return {
                ...elm,
                index: index + 1 + paginationModel.page * paginationModel.pageSize,
                dateConvert: moment(elm.createdAt).format('MM-DD-YYYY'),
              }
            })

        setDataForTable(dataConvert)
        setTotalRows(data?.total)

        // console.log(convertData)
      })
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  //column
  const columns = useMemo(
    () =>
      [
        {
          field: 'index',
          headerName: 'STT',
          width: 70,
          sortable: false,
        },
        {
          field: 'createdAt',
          headerName: 'Ngày yêu cầu',
          minWidth: 150,
          flex: 1,
          sortable: false,
          renderCell: ({ row }) => {
            return moment(row?.createdAt).format('hh:mm - DD/MM/YYYY')
          },
        },
        {
          field: 'amount',
          headerName: 'Số điểm',
          minWidth: 150,
          flex: 1,
          sortable: false,
          renderCell: ({ row }) => {
            return Intl.NumberFormat('en-US').format(row?.amount)
          },
        },
        {
          field: 'status',
          headerName: 'Trạng thái xử lý',
          minWidth: 150,
          flex: 1,
          sortable: false,
          renderCell: ({ row }) => {
            return (
              <Box>{
                row?.status === 0 && (
                  <Chip label='Chờ duyệt' size='medium' sx={{ backgroundColor: '#0091ea', minWidth: '90px', fontSize: '12px' }} />
                )
              }
                {
                  row?.status === 1 && (
                    <Chip label='Chấp nhận' size='medium' sx={{ backgroundColor: '#4caf50', minWidth: '90px', fontSize: '12px' }} />
                  )
                }
                {
                  row?.status === 2 && (
                    <Chip label='Từ chối' size='medium' sx={{ backgroundColor: '#ff5722', minWidth: '90px', fontSize: '12px' }} />
                  )
                }</Box>)
          },
        },
        {
          field: 'reason',
          headerName: 'Lý do(chấp nhận/từ chối)',
          minWidth: 150,
          flex: 1,
          sortable: false,
        },
      ].filter(Boolean),
    [dataForTable]
  )

  return (
    <Box
      m='20px'
      sx={{
        '& .MuiTypography-root.MuiTypography-h2': {
          margin: '0 0 5px 0',
        },
      }}
    >
      {messageContextHolder}
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Header title='LỊCH SỬ RÚT TIỀN' subtitle='Trang xem lịch sử rút tiền' />
      </Box>
      <Box
        height='75vh'
        m='40px 0 0 0'
        sx={{
          ...StyleDataGrid(),
          '& .maxValue': {
            color: theme.palette.mode === 'dark' ? '#44c144' : '#49ac49',
          },
        }}
      >
        <DataGridCustom
          rows={dataForTable}
          rowCount={totalRows}
          columns={columns}
          initialState={{
            pagination: { paginationModel },
            // density: 'comfortable',
          }}
          onPaginationModelChange={setPaginationModel}
          //pagination server side
          // sort server
          sortingMode='server'
          onSortModelChange={handleSortModelChange}
          loading={loading}
          disableColumnFilter
        />
      </Box>
    </Box>
  )
}

export default KOLsWithdrawalsHistory
