import React, { useState } from "react";
import {
  Box,
  Divider,
  TextField,
  Typography,
  useTheme,
  Button as ButtonMui,
  IconButton,
  CircularProgress,
  InputAdornment,
} from "@mui/material";
import { tokens } from "@src/theme";
import { message } from "antd";
import { useDispatch } from "react-redux";
import { phoneRegExp, passRegExp } from "@components/prefixData";
import { Formik } from "formik";
import { update_profile, user_change_password } from "@utils/api/apiList";
import {
  Email,
  Person,
  Phone,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import openMessageNotification from "@components/notification/message";
import getEventUpdateProfileAdminSlice from "@redux/features/getEventUpdateProfileAdminSlice";
import request from "@utils/api/request";
import useMediaQuery from "@mui/material/useMediaQuery";
import * as yup from "yup";
import "antd/dist/reset.css";

const profileSchema = yup.object().shape({
  fullName: yup.string().required("Không được để trống!"),
  email: yup
    .string()
    .email("Email không hợp lệ!")
    .required("Không được để trống!"),
  phoneNumber: yup
    .string()
    .matches(phoneRegExp, "Số điện thoại không hợp lệ!")
    .required("Không được để trống!"),
});

const passwordSchema = yup.object().shape({
  password: yup
    .string()
    .min(8, "Mật khẩu không đúng")
    .matches(passRegExp, "Mật khẩu tối thiểu là 8 ký tự bao gồm chữ và số")
    .required("Không được để trống!"),
  newPassword: yup
    .string()
    .min(8, "Mật khẩu mới tối thiểu là 8 ký tự bao gồm chữ và số")
    .matches(passRegExp, "Mật khẩu tối thiểu là 8 ký tự bao gồm chữ và số")
    .notOneOf(
      [yup.ref("password")],
      "Mật khẩu mới không được trùng với mật khẩu hiện tại"
    )
    .required("Không được để trống!"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("newPassword"), null], "Mật khẩu mới không khớp!")
    .required("Không được để trống!"),
});

function TabProfile({ userInfo, initialProfileValues, getUserProfile }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const matches = useMediaQuery("(min-width:900px)");

  const [initialPasswordValues, setInitialPasswordValues] = useState({
    password: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [loadingProfile, setLoadingProfile] = useState(null);
  const [loadingChangePassword, setLoadingChangePassword] = useState(null);

  //state redux
  const dispatch = useDispatch();

  //show pass
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showRenewPassword, setShowRenewPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowNewPassword = () => setShowNewPassword(!showNewPassword);
  const handleClickShowRenewPassword = () =>
    setShowRenewPassword(!showRenewPassword);

  //message
  const [messageApi, messageContextHolder] = message.useMessage();

  //fetch change password
  const fetchChangePassword = (data, resetForm, setFieldError) => {
    request
      .post(user_change_password, { ...data })
      .then((res) => {
        openMessageNotification(
          messageApi,
          "success",
          "changePasswordAccountCompany",
          userInfo?.fullName
        );
        !!getUserProfile && getUserProfile();
        resetForm();
      })
      .catch((err) => {
        const textErr = err?.response?.data?.data;
        if (!!err) {
          if (textErr === "PasswordIncorrect") {
            message.error("Mật khẩu cũ chưa đúng, vui lòng thử lại!");
            setFieldError("password", "Mật khẩu cũ chưa đúng!");
          }
        }
      })
      .finally(() => setLoadingChangePassword(false));
  };

  //updateProfile
  const fecthUpdateProfile = (data, resetForm, setFieldError) => {
    request
      .post(update_profile, { userInfo: { ...data } })
      .then((res) => {
        !!getUserProfile && getUserProfile();
        message.success({
          content: 'Cập nhật thông tin thành công!',
        });
        dispatch(
          getEventUpdateProfileAdminSlice.actions.eventUpdateProfileAdmin(
            res.data
          )
        );
      })
      .catch((err) => {
        const textErr = err.response.data.data;
        switch (textErr) {
          case "PhoneInUseInPartnerApp":
          case "PhoneInUseInUserApp":
          case "EmailInUse":
            message.error("Email đã được đăng ký!");
            setFieldError("email", "Email đã được đăng ký!");
            break;
          default:
            message.error("Cập nhật thông tin thất bại, vui lòng thử lại!");
            break;
        }

        console.error("Lỗi cập nhật profile kols", err);
      })
      .finally(() => setLoadingProfile(false));
  };

  //submit profile
  const handleSubmitProfile = (values, { resetForm, setFieldError }) => {
    const dataForm = {
      ...values,
      // fromCompany: userInfo?._id,
    };
    console.log(dataForm);

    setLoadingProfile(true);
    const timer = setTimeout(() => {
      fecthUpdateProfile(dataForm, resetForm, setFieldError);
    }, 500);

    return () => clearTimeout(timer);
  };

  //submit change passsword
  const handleSubmitPassword = (values, { resetForm, setFieldError }) => {
    const dataForm = {
      ...values,
      phoneNumber: userInfo?.phoneNumber,
    };

    delete dataForm.confirmPassword;

    setLoadingChangePassword(true);

    const timer = setTimeout(() => {
      fetchChangePassword(dataForm, resetForm, setFieldError);
    }, 500);

    return () => clearTimeout(timer);
  };

  return (
    <Box>
      <Formik
        onSubmit={(values, { setFieldError, resetForm }) =>
          handleSubmitProfile(values, { setFieldError, resetForm })
        }
        initialValues={initialProfileValues}
        enableReinitialize={true}
        validationSchema={profileSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              sx={{
                "& .MuiInputLabel-root.MuiInputLabel-formControl": {
                  fontSize: "14px", // default size when no data and not focused
                },
                "& .MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-shrink":
                  {
                    fontSize: "16px", // size when there is data or focused
                  },
                "& .MuiInputLabel-formControl.Mui-error.Mui-focused": {
                  fontSize: "16px !important",
                  color: theme.palette.mode === "dark" ? "#f44336" : "#d32f2f",
                },
                "& .MuiFormHelperText-root.Mui-error": {
                  fontSize: "14px",
                },
                "& fieldset": {
                  fontSize: "16px !important",
                },
              }}
            >
              <Typography
                variant="h5"
                fontWeight="600"
                ml={{ xs: 0, sm: "10%" }}
              >
                Cập nhật hồ sơ
              </Typography>
              <Box
                mt="20px"
                display="flex"
                flexDirection="column"
                maxWidth={{ xs: "auto", sm: "600px" }}
                ml={{ xs: 0, sm: "20%" }}
              >
                <TextField
                  sx={{ mr: { xs: 0, sm: "20px" } }}
                  variant="outlined"
                  label="Tên"
                  name="fullName"
                  value={values.fullName || ""}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={!!touched.fullName && !!errors.fullName}
                  helperText={touched.fullName && errors.fullName}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Person sx={{ mr: 2 }} />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  sx={{ mr: { xs: 0, sm: "20px" } }}
                  variant="outlined"
                  label="Email"
                  type="text"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email || ""}
                  error={!!touched.email && !!errors.email}
                  helperText={touched.email && errors.email}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Email sx={{ mr: 2 }} />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  sx={{ mr: { xs: 0, sm: "20px" } }}
                  variant="outlined"
                  label="Số điện thoại"
                  name="phoneNumber"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.phoneNumber || ""}
                  disabled
                  helperText={"Số điện thoại là duy nhất, không thể chỉnh sửa!"}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Phone sx={{ mr: 2 }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <ButtonMui
                sx={{
                  ml: { xs: 0, sm: "20%" },
                  background:
                    theme.palette.mode === "dark" ? "#138567" : "#3da58a",
                  fontWeight: "600",
                  width: "74px",
                  "&:hover": {
                    bgcolor: "#188b6d",
                  },
                }}
                type="submit"
                variant="contained"
              >
                {loadingProfile ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Lưu"
                )}
              </ButtonMui>
            </Box>
          </form>
        )}
      </Formik>
      <Divider
        sx={{
          ml: { xs: 0, sm: "10%" },
          mr: { xs: 0, sm: "25px" },
          mt: "20px",
        }}
      />
      <Formik
        onSubmit={(values, { setFieldError, resetForm }) =>
          handleSubmitPassword(values, { setFieldError, resetForm })
        }
        initialValues={initialPasswordValues}
        enableReinitialize={true}
        validationSchema={passwordSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              mt="20px"
              sx={{
                "& .MuiInputLabel-root.MuiInputLabel-formControl": {
                  fontSize: "14px", // default size when no data and not focused
                },
                "& .MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-shrink":
                  {
                    fontSize: "16px", // size when there is data or focused
                  },
                "& .MuiInputLabel-formControl.Mui-error.Mui-focused": {
                  fontSize: "16px !important",
                  color: theme.palette.mode === "dark" ? "#f44336" : "#d32f2f",
                },
                "& .MuiFormHelperText-root.Mui-error": {
                  fontSize: "14px",
                },
                "& fieldset": {
                  fontSize: "16px !important",
                },
              }}
            >
              <Typography
                variant="h5"
                fontWeight="600"
                ml={{ xs: 0, sm: "10%" }}
              >
                Cập nhật mật khẩu
              </Typography>
              <Box
                mt="20px"
                display="flex"
                flexDirection="column"
                maxWidth={{ xs: "auto", sm: "600px" }}
                ml={{ xs: 0, sm: "20%" }}
              >
                <TextField
                  sx={{ mr: { xs: 0, sm: "20px" } }}
                  variant="outlined"
                  label="Mật khẩu hiện tại"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password || ""}
                  error={!!touched.password && !!errors.password}
                  helperText={touched.password && errors.password}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        tabIndex={-1}
                        onClick={handleClickShowPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    ),
                  }}
                />
                <TextField
                  sx={{ mr: { xs: 0, sm: "20px" } }}
                  variant="outlined"
                  label="Mật khẩu mới"
                  name="newPassword"
                  type={showNewPassword ? "text" : "password"}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.newPassword || ""}
                  error={!!touched.newPassword && !!errors.newPassword}
                  helperText={touched.newPassword && errors.newPassword}
                  // helperText={
                  //     touched.newPassword && errors.newPassword
                  //         ? errors.newPassword
                  //         : 'Mật khẩu tối thiểu là 8 ký tự bao gồm chữ và số'
                  // }
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        tabIndex={-1}
                        onClick={handleClickShowNewPassword}
                      >
                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    ),
                  }}
                />
                <TextField
                  sx={{ mr: { xs: 0, sm: "20px" } }}
                  variant="outlined"
                  label="Xác nhận mật khẩu mới"
                  name="confirmPassword"
                  type={showRenewPassword ? "text" : "password"}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.confirmPassword || ""}
                  error={!!touched.confirmPassword && !!errors.confirmPassword}
                  helperText={touched.confirmPassword && errors.confirmPassword}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        tabIndex={-1}
                        onClick={handleClickShowRenewPassword}
                      >
                        {showRenewPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    ),
                  }}
                />
              </Box>
            </Box>
            <ButtonMui
              sx={{
                ml: { xs: 0, sm: "20%" },
                background:
                  theme.palette.mode === "dark" ? "#138567" : "#3da58a",
                fontWeight: "600",
                width: "74px",
                "&:hover": {
                  bgcolor: "#188b6d",
                },
              }}
              type="submit"
              variant="contained"
            >
              {loadingChangePassword ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Lưu"
              )}
            </ButtonMui>
          </form>
        )}
      </Formik>
    </Box>
  );
}

export default TabProfile;
