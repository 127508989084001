import { Box, TextField, Typography } from '@mui/material'
import React from 'react'

function TextFieldCustom({
    label,
    name,
    value,
    placeholder,
    onChange,
    onBlur = () => { },
    error = false,
    helperText = '',
    className,
    widthField = '80%',
    widthFieldXs = '100%',
    FormHelperTextProps,
    InputProps = {
        sx: {
            width: '100%'
        }
    },
    styleLabel,
    minWidthLabel,
    ...props
}) {
    return <Box
        className={className}
        sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            gap: { xs: '10px!important', sm: '20px!important' },
            alignItems: { xs: 'flex-start!important', sm: 'center!important' },
            width: { xs: `${widthFieldXs}!important`, sm: `${widthField}!important` },
        }}>
        <Typography style={styleLabel} minWidth={minWidthLabel}>{label}: </Typography>
        <TextField
            name={name}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            onBlur={onBlur}
            error={error}
            helperText={helperText}
            InputLabelProps={{
                sx: {
                    '&.MuiInputLabel-filled': {
                        fontSize: '14px',
                    },
                },
            }}
            FormHelperTextProps={FormHelperTextProps}
            InputProps={InputProps}
            sx={{ width: '100%' }}
            {...props}
        />
    </Box>
}

export default TextFieldCustom
