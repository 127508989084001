import { createSlice } from '@reduxjs/toolkit'

const getEventAddPartnerPermanentSlice = createSlice({
    name: 'getEventAddPartnerPermanent',
    initialState: {
        data: null,
    },
    reducers: {
        eventAddPartnerPermanent: (state, action) => {
            state.data = action.payload
            console.log(action)
        },
    },
})

export default getEventAddPartnerPermanentSlice
