import React, { useState, useEffect } from "react";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { tokens } from "@src/theme";
import {
  total_ref_today,
  total_waiting_done_order,
  total_done_order_to_get_bonus,
  order_of_users,
} from "@utils/api/apiList";
import Header from "@components/Header";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import AvTimerIcon from "@mui/icons-material/AvTimer";
import StatBox from "@components/StatBox";
import RevenuaChart from "./revenue_chart";
import request from "@src/utils/api/request";
import moment from "moment";

function Dashboard() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [newRef, setNewRef] = useState();
  const [refWaiting, setRefWaiting] = useState();
  const [totalDoneBonus, setTotalDoneBonus] = useState();
  const [orderUser, setOrderUser] = useState();

  useEffect(() => {
    fetchRefTodayData();
  }, []);

  const fetchRefTodayData = () => {
    request
      .post(total_ref_today, {})
      .then((response) => {
        const data = response?.data?.data;
        console.log("data", data);
        setNewRef(data?.length || 0);
      })
      .catch((error) => {
        console.error("Lỗi get all dữ liệu", error);
      });
  };

  useEffect(() => {
    fetchRefWaitingData();
  }, []);

  const fetchRefWaitingData = () => {
    request
      .post(total_waiting_done_order, {})
      .then((response) => {
        const data = response?.data?.data;
        console.log("data", data);
        setRefWaiting(data?.length || 0);
      })
      .catch((error) => {
        console.error("Lỗi get all dữ liệu", error);
      });
  };

  useEffect(() => {
    fetchTotalbonusData();
  }, []);

  const fetchTotalbonusData = () => {
    request
      .post(total_done_order_to_get_bonus, {})
      .then((response) => {
        const data = response?.data?.data;
        console.log("data", data);
        setTotalDoneBonus(data?.length || 0);
      })
      .catch((error) => {
        console.error("Lỗi get all dữ liệu", error);
      });
  };

  useEffect(() => {
    fetchOrderData();
  }, []);

  const fetchOrderData = () => {
    request
      .post(order_of_users, {})
      .then((response) => {
        const data = response?.data?.data;
        console.log("setOrderUser", data);
        setOrderUser(data);
      })
      .catch((error) => {
        console.error("Lỗi get all dữ liệu", error);
      });
  };

  const dataRow1 = [
    {
      title: newRef,
      subtitle: "Đăng kí mới hôm nay",
      icon: (
        <ExitToAppIcon
          sx={{ color: colors.greenAccent[600], fontSize: "36px" }}
        />
      ),
    },
    {
      title: refWaiting,
      subtitle: "Lời mời đang chờ",
      icon: (
        <AvTimerIcon
          sx={{ color: colors.greenAccent[600], fontSize: "36px" }}
        />
      ),
    },
    {
      title: totalDoneBonus,
      subtitle: "Số lượt cấp thưởng",
      icon: (
        <LeaderboardIcon
          sx={{ color: colors.greenAccent[600], fontSize: "36px" }}
        />
      ),
    },
  ];

  return (
    <Box
      m="20px"
      sx={{
        "& .MuiTypography-root.MuiTypography-h2": {
          margin: "0 0 5px 0",
        },
      }}
    >
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header
          title="TRANG CHỦ"
          subtitle="Chào mừng đến với trang tổng quản lý EClean"
        />

        <Box
          sx={{
            "& .css-q34qwa-MuiButtonBase-root-MuiButton-root:hover": {
              bgcolor: colors.primary[400],
            },
            "& .css-3mc8sk-MuiButtonBase-root-MuiButton-root:hover": {
              bgcolor: "rgba(4, 5, 9, 0.04)",
              color: "black",
            },
          }}
        >
          {/* <Button
                        sx={{
                            backgroundColor: colors.blueAccent[700],
                            color: 'white',
                            fontSize: '14px',
                            fontWeight: 'bold',
                            padding: '10px 20px',
                        }}
                    >
                        <DownloadOutlinedIcon sx={{ mr: '10px' }} />
                        TẢI BÁO CÁO
                    </Button> */}
        </Box>
      </Box>

      {/* GRID & CHARTS */}
      {/* ROW 1 */}
      <Grid container spacing={2} marginTop="30px">
        {dataRow1?.map((data, i) => (
          <Grid item xs={12} sm={4} md={4} key={i}>
            <Box
              boxShadow={
                theme.palette.mode === "dark"
                  ? "0 4px 6px 0 #0f1625"
                  : "0 4px 6px 0 #f2f2f2"
              }
              bgcolor={colors.primary[400]}
              display="flex"
              alignItems="center"
              justifyContent="center"
              height="140px"
            >
              <StatBox
                title={data.title}
                subtitle={data.subtitle}
                icon={data.icon}
              />
            </Box>
          </Grid>
        ))}
      </Grid>

      <Box
        display="grid"
        // gridTemplateColumns='repeat(12, 1fr)'
        gridTemplateColumns={{
          xs: "repeat(1, 1fr)", // 1 column on extra-small screens
          sm: "repeat(6, 1fr)", // 6 columns on small screens
          md: "repeat(8, 1fr)", // 8 columns on medium screens
          lg: "repeat(12, 1fr)", // 12 columns on large screens
        }}
        gridAutoRows="140px"
        gap="20px"
        marginTop="30px"
        sx={{
          "& .css-1wo04ma, .css-1vl72ks, .css-fxrul4, .css-3zn8qu, .css-1w5x3pn":
            {
              // backgroundColor: '#fff', rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px
              boxShadow:
                // 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px',
                "rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px",
            },
          "& .css-59jqr3-MuiSvgIcon-root,.css-1yqlyn3-MuiSvgIcon-root": {
            fontSize: "28px",
          },
        }}
      >
        {/* ROW 2 */}
        <Box
          gridColumn={{ xs: "span 12", sm: "span 8", lg: "span 8" }}
          gridRow="span 3"
          bgcolor={colors.primary[400]}
          boxShadow={
            theme.palette.mode === "dark"
              ? "0 4px 6px 0 #0f1625"
              : "0 4px 6px 0 #f2f2f2"
          }
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" flexDirection="column">
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                Thống Kê Doanh Thu
              </Typography>
              {/* <Typography variant='h3' fontWeight='700' color={colors.greenAccent[500]}>
                                10.000.000 VNĐ
                            </Typography> */}
            </Box>
            {/* <Box>
                             <IconButton>
                                <DownloadOutlinedIcon
                                    sx={{
                                        fontSize: '26px',
                                        color: colors.greenAccent[500],
                                    }}
                                />
                            </IconButton>
                        </Box> */}
          </Box>
          <Box height="70%" marginTop="10px">
            <RevenuaChart />
          </Box>
        </Box>

        {/* TRANSACTIONS */}
        <Box
          gridColumn={{ xs: "span 12", sm: "span 8", lg: "span 4" }}
          gridRow="span 3"
          bgcolor={colors.primary[400]}
          overflow="auto"
          boxShadow={
            theme.palette.mode === "dark"
              ? "0 4px 6px 0 #0f1625"
              : "0 4px 6px 0 #f2f2f2"
          }
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            color={colors.grey[100]}
            p="15px"
          >
            <Typography variant="h5" fontWeight="600" color={colors.grey[100]}>
              Đơn Đặt Hàng
            </Typography>
          </Box>
          {!!orderUser && orderUser?.length > 0 ? (
            orderUser?.map((elm, index) => {
              return (
                <Box
                  key={index}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  borderBottom={`4px solid ${colors.primary[500]}`}
                  p="15px"
                >
                  <Box>
                    <Typography
                      variant="h5"
                      fontWeight="600"
                      color={colors.greenAccent[500]}
                    >
                      {elm?.user?.fullName}
                    </Typography>
                    <Typography color={colors.grey[100]}>
                      {elm?.user?.phoneNumber}
                    </Typography>
                  </Box>
                  <Box color={colors.grey[100]}>
                    {moment(elm.firstOrderCreatedAt).format(
                      "hh:mm - dd/mm/yyyy"
                    )}
                  </Box>
                  <Box
                    bgcolor={colors.greenAccent[500]}
                    padding="5px 10px"
                    borderRadius="4px"
                  >
                    {elm?.bonusByOrder > 0
                      ? Intl.NumberFormat("en-US").format(elm?.bonusByOrder)
                      : 0}
                  </Box>
                </Box>
              );
            })
          ) : (
            <Box>
              <Typography textAlign="center" mt="20px">
                Đơn hàng đang trống
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default Dashboard;
