import { Box, Typography, useTheme } from '@mui/material'
import { tokens } from '../theme'
import ProgressCircle from './ProgressCircle'

const StatBox = ({ title, subtitle, icon }) => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    return (
        <Box width='100%' m='0 30px'>
            <Box display='flex' justifyContent='space-between' alignItems='center'>
                <Box>
                    <Typography variant='h2' fontWeight='bold' sx={{ color: colors.grey[100], marginBottom: '0!important' }}>
                        {title}
                    </Typography>
                </Box>
                <Box
                    display='flex'
                    alignItems='flex-end'
                    gap='10px'
                    sx={{
                        '& .MuiSvgIcon-root': {
                            color: colors.greenAccent[400],
                            fontSize: '80px',
                            opacity: 0.5
                        },
                    }}
                >
                    {icon}
                </Box>
            </Box>
            <Box display='flex' justifyContent='space-between' mt='8px'>
                <Typography variant='h5' sx={{ color: colors.greenAccent[400] }}>
                    {subtitle}
                </Typography>
                {/* <Typography variant='h5' fontStyle='italic' sx={{ color: colors.greenAccent[400] }}>
                    {increase}
                </Typography> */}
            </Box>
        </Box>
    )
}

export default StatBox
